import { useHistory } from "react-router-dom";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import PageTitle from "../components/Content/PageTitle";
import ReportErrandItem from "../components/Content/ReportErrandItem";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import useScreenSize from "../hooks/useScreenSize";
import { buildQueryString } from "../store/base";
import { useFilteredReportErrands } from "../store/reportErrands";
import { ChapterWrapper } from "./styles";

export default function Errands() {
  const [setting] = useCompanySetting();
  const { push } = useHistory();

  const { isMobile } = useScreenSize();

  const activeErrandsQ = buildQueryString({
    status__in: [0, 1, 2],
    order_by: ["-created_at"],
    has_no_common_area: true,
  });
  const [activeErrands] = useFilteredReportErrands(activeErrandsQ);

  const doneErrandsQ = buildQueryString({
    status__in: [3, 4],
    order_by: ["-done_time"],
  });
  const [doneErrands] = useFilteredReportErrands(doneErrandsQ);

  const propertyErrandsQ = buildQueryString({
    status_in: [0, 1, 2],
    order_by: ["-created_at"],
    has_no_common_area: false,
  });

  const [propertyErrands] = useFilteredReportErrands(propertyErrandsQ);

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle
        subtitle={
          "Gå in på ett aktivt ärende för att se uppdateringar och lämna kommentarer"
        }
        subSubtitle={`Jourinformation: ${
          setting?.jour_info || "Ingen jour tillgänglig"
        }`}
        renderAction={() => (
          <PrimaryButton
            fillWidth={isMobile}
            extraStyle={{ marginTop: isMobile ? 12 : 0 }}
            clicked={() => push("/create-errand")}
            title="Skapa ny felanmälan"
          />
        )}
      >
        Felanmälan
      </PageTitle>

      <ChapterWrapper>
        <Chapter title="Mina aktiva ärenden">
          <InnerBox>
            <ListItemWrapper>
              {activeErrands?.map((errand) => (
                <ReportErrandItem errand={errand} key={errand.id} />
              ))}

              {activeErrands?.length === 0 && (
                <ListItem>
                  <ListItemTitle>Inga aktiva ärenden</ListItemTitle>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>

        <Chapter title="Aktiva ärenden i min fastighet">
          <InnerBox>
            <ListItemWrapper>
              {propertyErrands?.map((errand) => (
                <ReportErrandItem errand={errand} key={errand.id} />
              ))}

              {propertyErrands?.length === 0 && (
                <ListItem>
                  <ListItemTitle>Inga aktiva ärenden</ListItemTitle>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>

        <Chapter title="Avslutade ärenden">
          <InnerBox>
            <ListItemWrapper>
              {doneErrands?.map((errand) => (
                <ReportErrandItem errand={errand} key={errand.id} />
              ))}

              {doneErrands?.length === 0 && (
                <ListItem>
                  <ListItemTitle>Inga avslutade ärenden</ListItemTitle>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
