import styled, { css } from "styled-components";
import arrowIcon from "../../assets/svg/arrow-right-white.svg";
import arrowBlueIcon from "../../assets/svg/arrow-right-blue.svg";

export const HeroWrapper = styled.div`
  margin-top: -60px;
  height: 220px;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  background-position: center;
  background-color: ${(p) => p.theme.colors.primaryBlueFaded};

  background-image: url(${(p) =>
    p.url
      ? p.url
      : "https://images.unsplash.com/photo-1487958449943-2429e8be8625?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"});

  background-size: cover;
  background-repeat: no-repeat;
  padding: 12px;

  @media (min-width: 769px) {
    width: calc(100vw - 260px);
    height: 160px;
  }
`;

export const CustomerLogo = styled.div`
  height: 120px;
  width: 120px;
  background-color: white;
  border-radius: 8px;
  background-position: center;
  background-image: url(${(p) =>
    p.url ? p.url : "https://pigello.se/static/img/pigello_logo_blue.svg"});
  background-size: 80%;
  background-repeat: no-repeat;
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  @media (min-width: 769px) {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    margin-bottom: 12px;
  }
`;

export const Chapter = styled.div``;
export const Title = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 6px;
`;

export const QuickLinkWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(1, 1fr);
`;

export const QuickLinkItem = styled.div`
  cursor: pointer;
  min-height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  border-radius: 6px;
`;

export const QuickLinkIconWrapper = styled.div`
  width: 44px;
  height: 44px;
  border: thin solid ${(p) => p.theme.colors.graphite};

  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-right: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
`;

export const QuickLinkArrow = styled.div`
  margin-left: auto;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${arrowBlueIcon});
`;

export const QuickLinkTitle = styled.div`
  display: flex;

  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: right;
`;
export const QuickLinkSubTitle = styled.div`
  display: flex;

  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  text-align: right;
`;

export const ListItemWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(1, 1fr);
`;

export const SplitListItemWrapper = styled(ListItemWrapper)`
  grid-template-columns: repeat(2, 1fr);
`;

export const ListItem = styled.div`
  padding: 8px;

  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};

  ${(p) =>
    p.primary &&
    css`
      background-color: ${(p) => p.theme.colors.primaryBlue};
    `}

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: ${(p) => p.theme.boxShadows.small};
      }
    `}
`;

export const ListItemTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const ListItemDate = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const NewsWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(1, 1fr);
`;

export const NewsItem = styled.div`
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
`;

export const NewsDate = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.textSecondary};
  margin-bottom: 6px;
`;
export const NewsTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const ErrandsWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(1, 1fr);
`;

export const Errand = styled.div`
  padding: 8px;
  border: thin solid #dddddd;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
`;

export const ErrandDate = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.textSecondary};
  margin-bottom: 6px;
`;
export const ErrandTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const StatusLabel = styled.div`
  align-self: flex-start;
  padding: 2px 6px;
  font-weight: 600;
  font-size: ${(p) => (p.large ? p.theme.fontSizes.headerSmall : "0.8rem")};
  border-radius: 3px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-flex;

  ${"" /* ******* contract statuses  ******* */}

  ${(p) =>
    p.state === "neutral" &&
    css`
      background-color: ${(p) => p.theme.colors.gray1};
      color: ${(p) => p.theme.colors.graphite};
    `}


  ${(p) =>
    (p.state === 0 || p.state === 1) &&
    css`
      background-color: #eafaf8;
      color: #2ec9b8;
    `}

    ${(p) =>
    p.state === 5 &&
    css`
      background-color: #f9efff;
      color: #cc68fb;
    `}

       ${(p) =>
    (p.state === 10 || p.state === 9) &&
    css`
      background-color: #f3f4f6;
      color: #5c667b;
    `}

         ${(p) =>
    p.state === 3 &&
    css`
      background-color: #fff8ec;
      color: #fdb738;
    `}

     ${(p) =>
    p.state === 2 &&
    css`
      background-color: #eafaf8;
      color: #2ec9b8;
    `}

       ${(p) =>
    p.state === 8 &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

        ${(p) =>
    p.state === 4 &&
    css`
      background-color: #eff2ff;
      color: #6883fb;
    `}

        ${(p) =>
    (p.state === 7 || p.state === 6) &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

    ${"" /* billecta statuses  */}

    ${(p) =>
    (p.state === "Okänd" || p.state === "Kreditfaktura") &&
    css`
      background-color: #e8e8e8;
      color: #2e2e2e;
    `}

      ${(p) =>
    p.state === "Förfallen (Ej hanterad)" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}


     ${(p) =>
    p.state === "Pausad" &&
    css`
      background-color: #ffeabd;
      color: #a67611;
    `}

     ${(p) =>
    p.state === "Obetald" &&
    css`
      background-color: #d6e4ff;
      color: #114ec2;
    `}

     ${(p) =>
    p.state === "Påminnelse" &&
    css`
      background-color: #c29eff;
      color: #4700ba;
    `}

       ${(p) =>
    p.state === "Inkasso" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

      ${(p) =>
    (p.state === "Slutbetald" || p.state === "Bokförd") &&
    css`
      background-color: #c7ffd0;
      color: #14a82c;
    `}

         ${(p) =>
    p.state === "Avbruten" &&
    css`
      background-color: #e8e8e8;
      color: #2e2e2e;
    `}

        ${(p) =>
    p.state === "Inkasso" &&
    css`
      background-color: #ffecf2;
      color: #fa3d56;
    `}

        ${(p) =>
    p.state === "Attesterad" &&
    css`
      background-color: #cafaf3;
      color: #0f7a6b;
    `}
`;

export const ErrandStatusLabel = styled.div`
  padding: 2px 6px;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 3px;
  color: ${(p) => p.theme.colors.primary};
  display: inline-flex;

  ${(p) =>
    p.state === -1 &&
    css`
      background-color: #f2f2f2;
      color: #111111;
    `}

  ${(p) =>
    p.state === 0 &&
    css`
      background-color: #f2ebff;
      color: #9258f0;
    `}

    ${(p) =>
    p.state === 1 &&
    css`
      background-color: #f2ebff;
      color: #9258f0;
    `}

     ${(p) =>
    [2, 3, 4].includes(p.state) &&
    css`
      color: rgb(97, 119, 220);
      background-color: rgb(240, 243, 254);
    `}
    ${(p) =>
    [5, 6, 7].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.yellow};
      background-color: ${(p) => p.theme.colors.yellowLight};
    `}
    ${(p) =>
    [8, 9, 10].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}
    ${(p) =>
    [11, 12, 13].includes(p.state) &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}

      ${(p) =>
    p.state === "paused" &&
    css`
      color: ${(p) => p.theme.colors.red};
      background-color: ${(p) => p.theme.colors.redLight};
    `}
      ${(p) =>
    p.state === "active" &&
    css`
      color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
    `}
`;

export const NotReadDot = styled.div`
  min-height: 10px;
  min-width: 10px;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.boxShadows.small};
`;

export const InfoItemWrapper = styled.div`
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:nth-of-type(even) {
    background-color: rgba(241, 242, 246, 0.35);
    border-radius: 3px;
  }
`;

export const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeLineItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;
  margin-bottom: 12px;
`;

export const TimeLineLineArea = styled.div`
  flex: 1;
  position: relative;
`;

export const TimeLineInfoArea = styled.div`
  flex: 6;
  border: ${(p) =>
    p.error ? p.theme.borders.standardRed : p.theme.borders.standard};
  box-shadow: ${(p) =>
    p.error ? p.theme.boxShadows.standardRed : p.theme.boxShadows.default};
  border-radius: 8px;
  background-color: white;
  padding: 12px;
`;

export const TimeLineDot = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  min-height: 16px;
  min-width: 16px;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.boxShadows.small};
`;

export const TimeLineBottomLine = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  min-width: 2px;
  min-height: 47px;

  background-color: ${(p) =>
    p.empty ? "transparent" : p.theme.colors.primaryBlue};
  opacity: 0.5;
`;

export const TimeLineTopLine = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  min-width: 2px;
  min-height: 35px;
  background-color: ${(p) =>
    p.empty ? "transparent" : p.theme.colors.primaryBlue};
  opacity: 0.5;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  border-radius: 5px;
  margin: 0 8px 8px 0;
  overflow: hidden;
`;

export const Image = styled.div`
  cursor: pointer;
  min-width: 120px;
  min-height: 120px;

  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ImageDescription = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  width: 100%;
  padding: 8px;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  max-height: 60px;
  overflow-y: auto;
`;

export const EmptyFile = styled.div`
  background-color: #fff;
  height: 180px;
  width: 140px;
  border: thin solid #ddd;
  border-radius: 6px;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: center;
`;

export const FileDisplay = styled(EmptyFile)`
  position: relative;
  background-color: #fff;
  height: 140px;
  width: 120px;
  border: thin solid #ddd;
  border-radius: 6px;
  padding: 12px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) => p.src});

  ${(p) =>
    p.name?.split(".")?.[1] &&
    css`
      &:after {
        position: absolute;
        content: "${p.name?.split(".")?.[1]}";
        left: calc(50% - 30px);
        top: calc(50& - 30px);
        width: 60px;
        height: 60px;
        font-size: 12px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: thin solid #ddd;
        background-color: rgba(256, 256, 256, 0.7);
        border-radius: 50%;
      }
    `}
`;

export const FileDisplayName = styled.div`
  position: absolute;
  padding: 4px;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(256, 256, 256, 0.6);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
