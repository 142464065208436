import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import useTenantInvoiceData from "../store/tenantInvoicing/hooks/retrieve";
import { toMoneyString } from "../store/utils";
import { ChapterWrapper } from "./styles";

export const SHOWN_STAGES_UNPAID = [
  "Attested",
  "InvoiceSent",
  "Manual",
  "ReminderInvoiceSent",
  "SentToDebtCollection",
];

const SHOWN_STAGES_PAID = ["CreditInvoice", "Completed"];

export const billectaStateMap = {
  None: "Okänd",
  Created: "Ej attesterad",
  ReminderInvoiceSent: "Påminnelse",
  Manual: "Obetald",
  Completed: "Slutbetald",
  InvoiceSent: "Obetald",
  Attested: "Attesterad",
  CreditInvoice: "Kreditfaktura",
  SentToDebtCollection: "Inkasso",
  SalesRequested: "SalesRequested",
  SaleRequestAccepted: "SaleRequestAccepted",
  SalesRequestedCancelled: "SalesRequestedCancelled",
  SalesRequestedDenied: "SalesRequestedDenied",
};

export default function Invoicing() {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [tenantInvoiceData, tenantInvoiceDataLoading] = useTenantInvoiceData();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();

  React.useEffect(() => {
    if (tenantInvoiceData !== undefined && !tenantInvoiceDataLoading) {
      setInvoiceData(tenantInvoiceData?.InvoiceActions || null);
    }
  }, [tenantInvoiceData, tenantInvoiceDataLoading]);

  const filteredInvoiceData = invoiceData?.filter((invoice) => {
    return SHOWN_STAGES_UNPAID.includes(invoice?.Stage);
  });

  const filteredPaidInvoiceData = invoiceData?.filter((invoice) => {
    return SHOWN_STAGES_PAID.includes(invoice?.Stage);
  });

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle>Mina avier</PageTitle>

      <ChapterWrapper>
        <Chapter title="Obetalda avier">
          <InnerBox>
            <ListItemWrapper>
              {(!filteredInvoiceData || filteredInvoiceData?.length === 0) && (
                <ListItemTitle>Inga obetalda avier </ListItemTitle>
              )}

              {filteredInvoiceData?.map((invoice) => (
                <ListItem
                  onClick={() => push(`/invoices/${invoice.ActionPublicId}`)}
                  key={invoice.ActionPublicId}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 6,
                      justifyContent: "space-between",
                    }}
                  >
                    <StatusLabel state={billectaStateMap[invoice.Stage]}>
                      {billectaStateMap[invoice.Stage]}
                    </StatusLabel>
                    <ListItemDate>
                      {moment(invoice.InvoiceDate).format("YYYY-MM-DD")}
                    </ListItemDate>
                  </div>

                  <ListItemTitle>
                    Förfallodatum:{" "}
                    {moment(invoice?.DueDate).format("YYYY-MM-DD")}
                  </ListItemTitle>

                  <ListItemTitle>
                    Fakturanr: {invoice?.InvoiceNumber}
                  </ListItemTitle>

                  <ListItemTitle>OCR: {invoice?.OCR}</ListItemTitle>

                  <ListItemTitle>
                    Belopp:{" "}
                    {toMoneyString(invoice?.InvoicedAmount?.ValueForView, true)}
                  </ListItemTitle>
                </ListItem>
              ))}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
        <Chapter title="Betalda avier">
          <InnerBox>
            <ListItemWrapper>
              {(!filteredPaidInvoiceData ||
                filteredPaidInvoiceData?.length === 0) && (
                <ListItemTitle>Inga betalda avier </ListItemTitle>
              )}

              {filteredPaidInvoiceData?.map((invoice) => (
                <ListItem
                  onClick={() => push(`/invoices/${invoice.ActionPublicId}`)}
                  key={invoice.ActionPublicId}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 6,
                      justifyContent: "space-between",
                    }}
                  >
                    <StatusLabel state={billectaStateMap[invoice.Stage]}>
                      {billectaStateMap[invoice.Stage]}
                    </StatusLabel>
                    <ListItemDate>
                      {moment(invoice.InvoiceDate).format("YYYY-MM-DD")}
                    </ListItemDate>
                  </div>

                  <ListItemTitle>
                    Förfallodatum:{" "}
                    {moment(invoice?.DueDate).format("YYYY-MM-DD")}
                  </ListItemTitle>

                  <ListItemTitle>
                    Fakturanr: {invoice?.InvoiceNumber}
                  </ListItemTitle>

                  <ListItemTitle>OCR: {invoice?.OCR}</ListItemTitle>

                  <ListItemTitle>
                    Belopp:{" "}
                    {toMoneyString(invoice?.InvoicedAmount?.ValueForView, true)}
                  </ListItemTitle>
                </ListItem>
              ))}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
