export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_LEASECONTRACT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_LEASECONTRACT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_LEASECONTRACT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_LEASECONTRACT",
  DESTROY_FORM: "DESTROY_FORM_LEASECONTRACT",
  SET_FORM_ERROR: "SET_FORM_ERROR_LEASECONTRACT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_LEASECONTRACT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_LEASECONTRACT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_LEASECONTRACT",
  RESET_STATE: "RESET_STATE_LEASECONTRACT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_LEASECONTRACT",
  REMOVE_OBJECT: "REMOVE_OBJECT_LEASECONTRACT",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_LEASECONTRACT",
  CLEAR_FETCHED: "CLEAR_FETCHED_LEASECONTRACT",

  LIST_URL: "/standard/mainlevel/contracts/leasecontract/list/",
  POST_URL: "/standard/mainlevel/contracts/leasecontract/",
  PATCH_URL: "/standard/mainlevel/contracts/leasecontract/",
  GET_URL: "/standard/mainlevel/contracts/leasecontract/",

  STORE_NAME: "leaseContracts",
};
