import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function fetchTerms(query) {
  const { data } = await axiosInstance.get(`${constants.LIST_TERMS_URL}?query`);

  return data;
}

async function getAnonCompanyData(companyKey) {
  try {
    const { data: companyData } = await axiosInstance.get(
      `${constants.GET_ANON_URL}${companyKey}/`
    );
    if (companyData) {
      return companyData;
    } else {
      return null;
    }
  } catch (e) {
    console.error("Could not fetch anon company data");
  }
}

export { fetchTerms, getAnonCompanyData };
