import moment from "moment";
import { useHistory } from "react-router-dom";
import { useErrandComponent } from "../../store/errandComponents";
import { getCurrentProductTypeUsage } from "../../store/errandComponents/utils";
import { getErrandStatus } from "../../store/reportErrands/utils";
import { ListItem, ListItemDate, ListItemTitle, StatusLabel } from "./styles";

export default function ReportErrandItem({ errand }) {
  const { colorStatus, display } = getErrandStatus(errand);

  const [component] = useErrandComponent(errand.component_placement?.id);
  const { push } = useHistory();
  const isParking = !!component?.parking_spot;

  const currentProductUsage = getCurrentProductTypeUsage(component);
  const productType = currentProductUsage?.product_type;
  const componentType = productType?.component_type;

  return (
    <ListItem onClick={() => push(`/errands/${errand.id}`)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <StatusLabel state={colorStatus}>{display}</StatusLabel>

        <ListItemDate>
          {moment(errand.created_at).format("YYYY-MM-DD")}
        </ListItemDate>
      </div>

      <ListItemTitle>
        {errand.errand_id} - {errand.str_representation}
      </ListItemTitle>
      {isParking ? (
        <ListItemDate>
          Komponent:{" "}
          <span style={{ fontWeight: 500 }}>
            {componentType?.str_representation}
          </span>{" "}
          på <span style={{ fontWeight: 500 }}>fordonsplats</span>
        </ListItemDate>
      ) : (
        <ListItemDate>
          Komponent:{" "}
          <span style={{ fontWeight: 500 }}>
            {componentType?.str_representation}
          </span>{" "}
          i <span style={{ fontWeight: 500 }}>{component?.room?.room_id}</span>
        </ListItemDate>
      )}
    </ListItem>
  );
}
