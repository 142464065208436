import * as React from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import PageTitle from "../components/Content/PageTitle";
import { StatusLabel } from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import {
  useLeaseContract,
  update as updateLease,
} from "../store/leaseContracts";
import {
  useOtherContract,
  update as updateOther,
} from "../store/otherContracts";
import {
  useParkingContract,
  update as updateParking,
} from "../store/parkingContracts";
import { ChapterWrapper } from "./styles";
import NonConnectedTextField from "../components/Form/NonConnectedTextField";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import { exportEditableDoc, useEditabledoc } from "../store/editabledocs";
import theme from "../theme";

const getHookFromType = (type) => {
  if (type === "lease-contract") return useLeaseContract;
  if (type === "other-contract") return useOtherContract;
  if (type === "parking-contract") return useParkingContract;
};

const getCancelFromType = (type) => {
  if (type === "lease-contract") return updateLease;
  if (type === "other-contract") return updateOther;
  if (type === "parking-contract") return updateParking;
};

const getStrFromType = (type) => {
  if (type === "lease-contract") return "Hyresavtal";
  if (type === "other-contract") return "Övrigt avtal";
  if (type === "parking-contract") return "Parkeringsavtal";
};

export default function Contract() {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const { push } = useHistory();

  const useContractHook = getHookFromType(type);
  const str = getStrFromType(type);
  const cancelFunction = getCancelFromType(type);

  const [contract, contractLoading] = useContractHook(id);
  const [editableDoc, editableDocLoading] = useEditabledoc(
    contract?.editabledoc?.id
  );
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const openEditableDoc = async () => {
    await exportEditableDoc({
      editableDoc: editableDoc,
    });
  };

  const openAttachment = async (a) => {
    window.open(a.pdf.get, "_blank").focus();
  };

  const getClosedDate = () => {
    // closed
    if (contract?.state === 6) {
      return contract?.closed_date || contract?.end_date;
    } else {
      // closed for renewal
      return contract?.closed_renew_date || contract?.end_date;
    }
  };

  const onCancel = () => {
    setLoading(true);
    dispatch(
      cancelFunction({
        id: id,
        forceData: {
          status: 6,
          closed_cause: cancelReason || "Ingen anledning lämnades",
        },
        successCallback: () => {
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {(contractLoading || loading) && <OverlaySpinner />}
      <Hero />

      <TextButton
        extraStyle={{ marginTop: 12 }}
        title="Tillbaka till alla avtal"
        iconType="arrow-back"
        clicked={() => push("/contracts")}
      />

      <PageTitle>
        {str} {contract?.str_representation}
      </PageTitle>

      <ChapterWrapper>
        <Chapter title="Information">
          <InnerBox>
            <InfoItem
              title="Status"
              value={
                <StatusLabel state={contract?.state}>
                  {contract?.state_display}
                </StatusLabel>
              }
            />
            <InfoItem
              title="Signerades"
              value={
                contract?.signed_date
                  ? moment(contract?.signed_date).format("YYYY-MM-DD")
                  : contract?.is_signed
                  ? "-"
                  : "Ej signerat"
              }
            />
            <InfoItem
              title="Startdatum"
              value={moment(contract?.start_date).format("YYYY-MM-DD")}
            />
            <InfoItem
              title="Uppsägningstid"
              value={durationParse(contract?.notify_interval, true)}
            />
            {(contract?.state === 6 || contract?.state === 7) && (
              <InfoItem title="Uppsagt" value={getClosedDate()} />
            )}
            {contract?.end_date && (
              <InfoItem
                title="Slutdatum"
                value={moment(contract?.end_date).format("YYYY-MM-DD")}
              />
            )}

            {(contract?.state === 0 ||
              contract?.state === 1 ||
              contract?.state === 2) &&
              !cancelOpen && (
                <PrimaryButton
                  extraStyle={{
                    marginTop: 12,
                    backgroundColor: theme().colors.red,
                  }}
                  title="Säg upp avtal"
                  clicked={() => setCancelOpen(true)}
                />
              )}

            {cancelOpen && (
              <InnerBox style={{ marginTop: 12 }}>
                <TextButton
                  extraStyle={{ marginBottom: 12 }}
                  title="Avbryt"
                  iconType="arrow-back"
                  clicked={() => setCancelOpen(false)}
                />

                <NonConnectedTextField
                  {...{
                    noMargin: true,
                    title: "Anledning till uppsägning",
                    noResize: true,
                    value: cancelReason,
                    handleChange: (val) => setCancelReason(val),
                    placeholder: "Skriv din anledning här",
                    rows: 2,
                  }}
                />

                <PrimaryButton
                  disabled={!cancelReason}
                  extraStyle={{
                    marginTop: 8,
                    backgroundColor: theme().colors.red,
                  }}
                  title="Säg upp avtal"
                  clicked={onCancel}
                />
              </InnerBox>
            )}
          </InnerBox>
        </Chapter>

        <Chapter title="Dokument">
          <InnerBox>
            {editableDoc && (
              <PrimaryButton title="Visa avtal" clicked={openEditableDoc} />
            )}

            {editableDoc?.attachments?.length > 0 && (
              <InnerBox style={{ marginTop: 12 }}>
                <div
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 500,
                    marginBottom: 6,
                  }}
                >
                  Bilagor
                </div>
                {editableDoc?.attachments?.map((a) => (
                  <InnerBox key={a.id} style={{ marginBottom: 6 }}>
                    <TextButton
                      title={a.str_representation}
                      clicked={() => openAttachment(a)}
                    />
                  </InnerBox>
                ))}
              </InnerBox>
            )}
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}

export const durationParse = (durationStamp, verbose) => {
  if (!durationStamp) {
    return undefined;
  }
  const split = durationStamp.split(" ");

  const years = parseInt(split[0]);
  const months = parseInt(split[1]);
  const weeks = parseInt(split[2]);
  const days = parseInt(split[3]);

  let presentation = "-";
  let type = "";

  if (years !== 0) {
    presentation = years;
    type = "år";
  } else if (months !== 0) {
    presentation = months;
    type = "månader";
  } else if (weeks !== 0) {
    presentation = weeks;
    type = "veckor";
  } else if (days !== 0) {
    presentation = days;
    type = "dagar";
  }

  if (verbose) {
    return `${presentation} ${type}`;
  }

  return { presentation, type };
};
