import * as React from "react";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../store/base";
import { useReportErrandsPagination } from "../../store/reportErrands";
import TextButton from "../Buttons/TextButton";
import { InnerBox } from "../sharedStyles";
import ReportErrandItem from "./ReportErrandItem";
import * as SC from "./styles";

export default function MyErrands() {
  const { push } = useHistory();
  const activeErrandsQ = buildQueryString({
    status__in: [0, 1, 2],
    order_by: ["-created_at"],
    _page: 1,
    _page_size: 3,
    has_no_common_area: true,
  });
  const [activeErrands] = useReportErrandsPagination(activeErrandsQ);

  return (
    <InnerBox>
      <SC.ListItemWrapper>
        {activeErrands?.results?.map((errand) => (
          <ReportErrandItem errand={errand} key={errand.id} />
        ))}

        {activeErrands?.results?.length === 0 && (
          <SC.ListItem>
            <SC.ListItemTitle>Inga aktiva ärenden</SC.ListItemTitle>
          </SC.ListItem>
        )}
        <TextButton
          title="Visa alla ärenden"
          iconType="arrow"
          iconPlacement="right"
          clicked={() => push("/errands")}
        />
      </SC.ListItemWrapper>
    </InnerBox>
  );
}
