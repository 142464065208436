import * as React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "../../store/base";
import { useSendoutsPagination } from "../../store/sendout";
import TextButton from "../Buttons/TextButton";
import * as SC from "./styles";
import { InnerBox } from "../sharedStyles";

export default function News() {
  const [sendoutPage, setSendoutPage] = React.useState(1);
  const [hasNextSendoutPage, setHasNextSendoutPage] = React.useState(false);

  const sendoutPageSize = 3;

  const sendoutQuery = buildQueryString({
    order_by: ["-pinned", "-scheduled_for", "-sent_at"],
    _page: sendoutPage,
    _page_size: sendoutPageSize,
  });

  const [sendouts, sendoutsLoading] = useSendoutsPagination(sendoutQuery);

  React.useEffect(() => {
    if (!!sendouts?.next != hasNextSendoutPage) {
      setHasNextSendoutPage(!!sendouts?.next);
    }
  }, [sendouts?.next]);

  const { push } = useHistory();

  return (
    <InnerBox>
      <SC.NewsWrapper>
        {sendouts?.results?.map((s) => (
          <SC.NewsItem onClick={() => push(`/news/${s.id}`)} key={s.id}>
            <SC.NewsDate>
              {s.pinned
                ? "Fäst utskick"
                : moment(s.scheduled_for).format("YYYY-MM-DD")}{" "}
            </SC.NewsDate>
            <SC.NewsTitle>{s.title}</SC.NewsTitle>
          </SC.NewsItem>
        ))}

        {sendouts?.results?.length === 0 && (
          <SC.NewsItem>
            <SC.NewsTitle>Inga nyheter är publicerade än</SC.NewsTitle>
          </SC.NewsItem>
        )}

        {hasNextSendoutPage && (
          <TextButton
            title="Visa alla nyheter"
            iconType="arrow"
            iconPlacement="right"
            clicked={() => push("/news")}
          />
        )}
      </SC.NewsWrapper>
    </InnerBox>
  );
}
