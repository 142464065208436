import { useSelector } from "react-redux";
import { useTenantPortalSetting } from "../store/portalSettings";

export default function useCompanySetting() {
  const settingId = useSelector((state) => state.app.tenantPortalSetting);

  const [setting, settingLoading] = useTenantPortalSetting(settingId);

  return [setting, settingLoading];
}
