import * as React from "react";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import { ChapterWrapper } from "./styles";

import { getTerms } from "../store/portalSettings";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../store/toasts";
import TextButton from "../components/Buttons/TextButton";
import useScreenSize from "../hooks/useScreenSize";

export default function DocumentsAndInfo() {
  const dispatch = useDispatch();
  const [setting, settingLoading] = useCompanySetting();
  const [terms, setTerms] = React.useState([]);
  const { isMobile } = useScreenSize();
  React.useEffect(() => {
    if (setting && terms?.length === 0) {
      dispatch(
        getTerms({
          ids: setting?.terms?.map((t) => t.id),
          successCallback: (data) => setTerms(data),
          errorCallback: () => {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: "Kunde ej hämta in dokument",
              })
            );
          },
        })
      );
    }
  }, [setting]);

  const openDocument = (url) => {
    window.open(url, "", "height=650,width=840");
  };

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle>Dokument och information</PageTitle>

      <ChapterWrapper>
        <Chapter title="Kontaktuppgifter">
          <InnerBox>
            {setting?.contact_persons?.map((cp) => (
              <ListItem key={cp.id}>
                <ListItemTitle>
                  {cp.str_representation}, {cp.title}
                </ListItemTitle>
                <ListItemDate>Tel: {cp.phone || "-"}</ListItemDate>
                <ListItemDate>Epost: {cp.email || "-"}</ListItemDate>
              </ListItem>
            ))}

            {(!setting || setting?.contact_persons?.length === 0) && (
              <ListItem>
                <ListItemTitle>Inga kontaktpersoner är angivna</ListItemTitle>
              </ListItem>
            )}
          </InnerBox>
        </Chapter>

        <Chapter title="Dokument">
          <InnerBox>
            {terms?.map((t) => (
              <ListItem key={t.id}>
                <ListItemDate>{t.category_display}</ListItemDate>
                <ListItemTitle>{t.title}</ListItemTitle>
                <TextButton
                  extraStyle={{ padding: 0 }}
                  title="Visa dokument"
                  clicked={() => openDocument(t.doc.get)}
                  iconType="arrow"
                  iconPlacement="right"
                />
              </ListItem>
            ))}

            {terms?.length === 0 && (
              <ListItem>
                <ListItemTitle>Inga dokument är tillgängliga</ListItemTitle>
              </ListItem>
            )}
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
