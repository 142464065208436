import { push, replace } from "connected-react-router";

import {
  clearUserData,
  getIntercomHashKey,
  getId,
  getToken,
  setCustomerData,
  setIntercomHashKey,
  setToken,
  setUserCreatedDate,
  setUserId,
  getUserCreatedDate,
  getCustomer,
  setTenantPortalSetting,
  getTenantPortalSetting,
  setIntercomData,
  setIsAvyAuth,
  getIsAvyAuth,
} from "../localStorage";

import { performLogutRedirect } from "../base/store/axios";
import { axiosInstance } from "../base/store/axios";
import { resetAllStates } from "../utils";

import constants from "./constants";
import * as services from "./services";
import { addToast } from "../toasts";

export const localLogin = () => {
  return (dispatch) => {
    dispatch({
      type: constants.START_LOCAL_LOGIN,
    });

    const { token } = getToken();
    const localToken = token;

    const localUserId = getId();
    const hashKey = getIntercomHashKey();
    const userCreatedDate = getUserCreatedDate();
    const customer = getCustomer();
    const tenantPortalSetting = getTenantPortalSetting();
    const isAvyAuth = getIsAvyAuth();

    if (localToken && localUserId) {
      dispatch({
        type: constants.LOCAL_LOGIN_SUCCESSFUL,
        payload: {
          token: localToken,
          userId: localUserId,
          tenant_portal_setting: tenantPortalSetting,
          isAvyAuth: isAvyAuth,
          intercom: {
            user_hash_key: hashKey,
            customer: customer,
            user_created_date: userCreatedDate,
          },
        },
      });
      dispatch(getUser(localUserId));
    } else {
      clearUserData();
      dispatch({
        type: constants.LOCAL_LOGIN_FAILED,
      });
      dispatch(resetAllStates());
    }
  };
};

export const login = (username, password, next, mfaCallback, mfaCode) => {
  return async (dispatch) => {
    dispatch({
      type: constants.START_LOGIN,
    });

    const body = { username, password, mfa_code: mfaCode };

    try {
      const { data, status } = await axiosInstance.post(
        "/accounts/auth/token_auth/",
        body
      );

      // if (status === 206) {
      //   dispatch({
      //     type: constants.MFA_PENDING,
      //   });
      //   mfaCallback();
      //   return;
      // }

      setToken(data.token);
      setUserId(data.user_id);
      setIntercomHashKey(data.intercom?.user_hash_key);
      setUserCreatedDate(data.intercom?.user_created_date);
      setCustomerData(data.intercom?.customer);
      setTenantPortalSetting(data?.tenant_portal_setting);

      if (data.intercom) {
        setIntercomData(data.intercom);
      } else {
        localStorage.removeItem("intercom_data");
      }

      const forceMfaSetting = data.enforce_mfa && !data.has_mfa;

      dispatch({
        type: constants.LOGIN_SUCCESSFUL,
        payload: {
          ...data,
        },
      });

      if (forceMfaSetting) {
        dispatch({
          type: constants.MFA_SETTING,
          payload: {
            show: true,
            enforce: true,
          },
        });
      }

      dispatch(getUser(data.user_id));

      dispatch(push(next || "/"));
    } catch (error) {
      dispatch({
        type: constants.LOGIN_FAILED,
        payload: {
          error: error?.response?.status,
        },
      });
    }
  };
};

export const yourblockLogin = (yourblockToken, errorCallback) => {
  return async (dispatch) => {
    dispatch({
      type: constants.START_LOGIN,
    });

    const body = { yourblock_bearer_token: yourblockToken };

    try {
      const { data } = await axiosInstance.post(
        "/accounts/auth/yourblock_auth/",
        body
      );
      if (data?.user_type !== 3) {
        // Check if the user is a tenant or not, if not don't login and make the user aware.
        dispatch({
          type: constants.LOGIN_FAILED,
          payload: {
            error: "Inte en hyresgäst",
          },
        });

        dispatch(
          addToast(
            "Du är inte en hyresgäst.",
            "Vänligen logga in på rätt portal.",
            "ERROR"
          )
        );
        errorCallback();
        return;
      }

      setToken(data.token);
      setUserId(data.user_id);
      setIsAvyAuth(false);

      dispatch({
        type: constants.LOGIN_SUCCESSFUL,
        payload: {
          ...data,
        },
      });

      dispatch(getUser(data.user_id));
      dispatch(push(`/home`));
    } catch (error) {
      dispatch({
        type: constants.LOGIN_FAILED,
        payload: {
          error: error?.response?.status,
        },
      });
      if (error?.response?.status === 400) {
        dispatch(
          addToast(
            "Autentisieringen misslyckades.",
            "Vänligen kontrollera användarnamn och lösenord.",
            "ERROR"
          )
        );
      }
      errorCallback();
    }
  };
};

export const avyLogin = (avyToken, errorCallback, nextUrl) => {
  return async (dispatch) => {
    dispatch({
      type: constants.START_LOGIN,
    });

    const body = { avytmpl_bearer_token: avyToken };

    try {
      const { data } = await axiosInstance.post(
        "/accounts/auth/avytmpl_auth/",
        body
      );
      if (data?.user_type !== 3) {
        // Check if the user is a tenant or not, if not don't login and make the user aware.
        dispatch({
          type: constants.LOGIN_FAILED,
          payload: {
            error: "Inte en hyresgäst",
          },
        });

        dispatch(
          addToast(
            "Du är inte en hyresgäst.",
            "Vänligen logga in på rätt portal.",
            "ERROR"
          )
        );
        errorCallback();
        return;
      }

      setToken(data.token);
      setUserId(data.user_id);
      setIsAvyAuth(true);

      dispatch({
        type: constants.LOGIN_SUCCESSFUL,
        payload: {
          ...data,
          isAvyAuth: true,
        },
      });

      dispatch(getUser(data.user_id));
      dispatch(replace(nextUrl || `/faultreports`));
    } catch (error) {
      dispatch({
        type: constants.LOGIN_FAILED,
        payload: {
          error: error?.response?.status,
        },
      });
      if (error?.response?.status === 400) {
        dispatch(
          addToast(
            "Autentisieringen misslyckades.",
            "Vänligen kontrollera användarnamn och lösenord.",
            "ERROR"
          )
        );
      }
      errorCallback();
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    clearUserData();

    dispatch({
      type: constants.LOG_OUT,
    });

    performLogutRedirect("logout");
  };
};

export const getUser = (userId) => {
  return async (dispatch) => {
    const user = await services.getUser(userId);

    // FRESHDESK DATA
    if (window.fcWidget && user) {
      // To set unique user id in your system when it is available
      window.fcWidget.setExternalId(user.id);

      // To set user name
      window.fcWidget.user.setFirstName(user.str_representation);

      // To set user email
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setPhone(user.phone);

      // To set user properties
      window.fcWidget.user.setProperties({
        userType: user.user_type_display,
        userName: user.username,
        phone: user.phone,
        firstName: user.first_name,
        lastName: user.last_name,
        isCompany: user.is_company,
        corporation: user.corporate_name,
        language: user.language_display,
        activeTo: user.active_to,
      });
    }

    dispatch({
      type: constants.GET_USER,
      payload: {
        user,
      },
    });
  };
};

const mobileMediaQuery = window.matchMedia("(max-width: 768px)");
const smallDesktopQuery = window.matchMedia("(max-width: 1200px)");
export const setScreenSize = () => {
  return async (dispatch) => {
    const isMobile = mobileMediaQuery.matches;
    const isSmallDesktop = smallDesktopQuery.matches;

    dispatch({
      type: constants.SET_SCREEN_SIZE,
      payload: {
        isMobile,
        isSmallDesktop,
      },
    });
  };
};
