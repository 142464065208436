import { useHistory } from "react-router-dom";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useFilteredApartments } from "../store/apartments";
import { buildQueryString } from "../store/base";
import { useFilteredBrfPremises } from "../store/brfPremises";
import { useFilteredIndustrialPremises } from "../store/industrialPremises";
import { useFilteredParkingSpots } from "../store/parkingSpots";

import { ChapterWrapper } from "./styles";

export default function Objects() {
  const { isMobile } = useScreenSize();
  const query = buildQueryString({
    state__in: [0, 1, 2],
  });
  const { push } = useHistory();
  const [apartments, apartmentsLoading] = useFilteredApartments(query);
  const [indps, indpLoading] = useFilteredIndustrialPremises(query);
  const [parkingSpots, parkingSpotsLoading] = useFilteredParkingSpots(query);
  const [brfPremises, brfPremisesLoading] = useFilteredBrfPremises("");

  return (
    <>
      {(apartmentsLoading ||
        indpLoading ||
        parkingSpotsLoading ||
        brfPremisesLoading) && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <PageTitle subtitle={"Gå in på ett objekt för att se detaljer"}>
        Mina objekt
      </PageTitle>

      <ChapterWrapper>
        {apartments?.length > 0 && (
          <Chapter title="Mina lägenheter">
            <InnerBox>
              <ListItemWrapper>
                {apartments?.map((lc) => {
                  return (
                    <ListItem
                      onClick={() => push(`/objects/apartment/${lc.id}`)}
                      key={lc.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <StatusLabel state={lc.state}>
                          {lc.state_display}
                        </StatusLabel>

                        <ListItemDate>
                          {lc.category_display}, {lc.area}m²
                        </ListItemDate>
                      </div>

                      <ListItemTitle>Lgh {lc.str_representation}</ListItemTitle>
                      <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                    </ListItem>
                  );
                })}
              </ListItemWrapper>
            </InnerBox>
          </Chapter>
        )}

        {brfPremises?.length > 0 && (
          <Chapter title="Mina lägenheter">
            <InnerBox>
              <ListItemWrapper>
                {brfPremises?.map((lc) => {
                  return (
                    <ListItem
                      onClick={() => push(`/objects/brf/${lc.id}`)}
                      key={lc.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <StatusLabel state={0}>{"Bostadsrätt"}</StatusLabel>

                        <ListItemDate>
                          {lc.category_display}, {lc.area}m²
                        </ListItemDate>
                      </div>

                      <ListItemTitle>Lgh {lc.str_representation}</ListItemTitle>
                      <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                    </ListItem>
                  );
                })}
              </ListItemWrapper>
            </InnerBox>
          </Chapter>
        )}

        {indps?.length > 0 && (
          <Chapter title="Mina lokaler">
            <InnerBox>
              <ListItemWrapper>
                {indps?.map((lc) => {
                  return (
                    <ListItem
                      onClick={() =>
                        push(`/objects/industrial-premises/${lc.id}`)
                      }
                      key={lc.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <StatusLabel state={lc.state}>
                          {lc.state_display}
                        </StatusLabel>

                        <ListItemDate>
                          {lc.category_display}, {lc.area}m²
                        </ListItemDate>
                      </div>

                      <ListItemTitle>
                        Lokal {lc.str_representation}
                      </ListItemTitle>
                      <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                    </ListItem>
                  );
                })}
              </ListItemWrapper>
            </InnerBox>
          </Chapter>
        )}

        {parkingSpots?.length > 0 && (
          <Chapter title="Mina parkeringsplatser">
            <InnerBox>
              <ListItemWrapper>
                {parkingSpots?.map((lc) => {
                  return (
                    <ListItem
                      onClick={() => push(`/objects/parking/${lc.id}`)}
                      key={lc.id}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <StatusLabel state={lc.state}>
                          {lc.state_display}
                        </StatusLabel>

                        <ListItemDate>{lc.category_display}</ListItemDate>
                      </div>

                      <ListItemTitle>
                        Parkering {lc.str_representation}
                      </ListItemTitle>
                      <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                    </ListItem>
                  );
                })}
              </ListItemWrapper>
            </InnerBox>
          </Chapter>
        )}
      </ChapterWrapper>
    </>
  );
}
