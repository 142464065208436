import { cloneDeep } from "lodash";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../components/Content/styles";
import TimeLine from "../components/Content/TimeLine";
import NonConnectedTextField from "../components/Form/NonConnectedTextField";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useApartment } from "../store/apartments";
import { buildQueryString } from "../store/base";
import { axiosInstance } from "../store/base/store/axios";
import { useBrfPremises } from "../store/brfPremises";
import { useIndustrialPremises } from "../store/industrialPremises";
import { useInspectionErrand } from "../store/inspectionErrands";
import { reverseCreateNote, useFilteredNotes } from "../store/notes";
import { getErrandStatus } from "../store/reportErrands/utils";
import theme from "../theme";
import { ChapterWrapper } from "./styles";

export default function InspectionErrand() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { push } = useHistory();
  const [errand, errandLoading] = useInspectionErrand(id);
  const [newComment, setNewComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const userId = useSelector((state) => state.app.userId);
  const { isMobile } = useScreenSize();

  const [apartment] = useApartment(errand?.apartment?.id);
  const [indp] = useIndustrialPremises(errand?.industrial_premises?.id);
  const [brfPremises] = useBrfPremises(errand?.brf_premis?.id);

  const premises = apartment || indp || brfPremises;

  const reportCommentsQuery = buildQueryString({
    id__in: errand?.report_comments?.map((n) => n.id) || [],
  });
  const [reportComments] = useFilteredNotes(reportCommentsQuery);

  const actualComments = React.useMemo(() => {
    const actual = cloneDeep(reportComments || [])?.splice(
      1,
      reportComments?.length || 1
    );
    return actual;
  }, [reportComments]);

  const { colorStatus, display } = getErrandStatus(errand);

  const timeLineItems = React.useMemo(() => {
    if (!errand) return [];
    const items = [];

    items.push({
      title: "Ärendet skapades",
      date: moment(errand.created_at).format("YYYY-MM-DD HH:mm"),
    });

    if (errand.planned_start) {
      items.push({
        title: "Ärendet planeras att påbörjas",
        date: moment(errand.planned_start).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.execute_start) {
      items.push({
        title: "Ärendet påbörjades",
        date: moment(errand.execute_start).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.planned_end) {
      items.push({
        title: "Ärendet planeras att avslutas",
        date: moment(errand.planned_end).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.execute_end) {
      items.push({
        title: "Ärendet avslutades",
        date: moment(errand.execute_end).format("YYYY-MM-DD HH:mm"),
      });
    }

    return items;
  }, [errand]);

  const addComment = () => {
    setLoading(true);

    const noteObj = {
      content: newComment,
      owner: { id: userId },
      content_type: "errands.inspectionerrand",
      object_id: id,
      attr_name: "report_comments",
    };

    dispatch(
      reverseCreateNote({
        noteObj,
        successCallback: () => {
          setNewComment("");
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const getProtocol = async () => {
    try {
      const { data } = await axiosInstance.post(
        "/reporting/generators/powerpoint/run_report/errands_inspectionerrandpresentationgenerator/",
        {
          additional_kwargs: {
            errand_id: parseInt(id),
          },
        }
      );

      const docId = data.id;

      const {
        data: { data: pdfData },
      } = await axiosInstance.get(
        `/reporting/generators/powerpoint/pdfprintdata/${docId}/`
      );
      setResult(pdfData);
    } catch (e) {
      return;
    }
  };

  React.useEffect(() => {
    getProtocol();
  }, []);

  const download = () => {
    const linkSource = `data:application/pdf;base64,${result}`;
    const downloadLink = document.createElement("a");
    const fileName = `${errand?.errand_id}_protokoll_${moment().format(
      "YYYY-MM-DD"
    )}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      {(errandLoading || loading) && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <TextButton
        extraStyle={{ marginTop: 12 }}
        title="Tillbaka till översikt"
        iconType="arrow-back"
        clicked={() => push("/inspections")}
      />

      <PageTitle
        renderAction={
          [3, 4].includes(errand?.status) && result
            ? () => (
                <PrimaryButton
                  fillWidth={isMobile}
                  extraStyle={{ marginTop: isMobile ? 12 : 0 }}
                  clicked={download}
                  title="Visa protokoll"
                />
              )
            : null
        }
      >
        {errand?.errand_id} - {errand?.str_representation}
      </PageTitle>

      <ChapterWrapper>
        <Chapter title="Ärendeinformation">
          <InnerBox>
            <InfoItem title="Ärendenummer" value={errand?.errand_id} />
            <InfoItem title="Titel" value={errand?.title} />
            <InfoItem
              title="Objekt"
              value={`${
                premises?.kind === "apartment"
                  ? "Lgh"
                  : premises?.kind === "industrialpremises"
                  ? "Lokal"
                  : "Bostadsrätt"
              } ${premises?.str_representation}`}
            />

            <InfoItem
              title="Uppdaterades senast"
              value={moment(errand?.updated_at).format("YYYY-MM-DD HH:mm")}
            />

            <InnerBox style={{ marginTop: 8, padding: 6 }}>
              <div
                style={{
                  fontSize: theme().fontSizes.headerXSmall,
                  fontWeight: 500,
                  marginBottom: 6,
                }}
              >
                Beskrivning
              </div>
              <p
                style={{
                  fontSize: theme().fontSizes.headerXSmall,
                  fontWeight: 400,
                }}
                dangerouslySetInnerHTML={{
                  __html: reportComments?.[0]?.content?.replaceAll(
                    "\n",
                    "<br>"
                  ),
                }}
              />
            </InnerBox>
          </InnerBox>
        </Chapter>

        <Chapter title="Ärendestatus">
          <InnerBox>
            <TimeLine items={timeLineItems} />
          </InnerBox>
        </Chapter>

        <Chapter title="Kommentarer">
          <InnerBox>
            <ListItemWrapper>
              {actualComments?.map((c) => (
                <ListItem
                  style={{
                    backgroundColor:
                      c.owner?.id == userId
                        ? theme().colors.greenLight
                        : theme().colors.yellowLight,
                  }}
                  key={c.id}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ListItemDate>
                      {c.owner?.id == userId
                        ? "Jag"
                        : c.owner?.str_representation}{" "}
                    </ListItemDate>
                    <ListItemDate>
                      {moment(c.created_at).format("YYYY-MM-DD")}
                    </ListItemDate>
                  </div>
                  <ListItemTitle>{c.content}</ListItemTitle>
                </ListItem>
              ))}
            </ListItemWrapper>

            <InnerBox style={{ marginTop: 12 }}>
              <NonConnectedTextField
                {...{
                  noMargin: true,

                  title: "Lägg till kommentar",

                  noResize: true,
                  value: newComment,
                  handleChange: (val) => setNewComment(val),
                  placeholder: "Skriv din kommentar här",
                  rows: 2,
                }}
              />
              <PrimaryButton
                disabled={!newComment?.length}
                extraStyle={{ marginTop: 4 }}
                title="Lägg till kommentar"
                clicked={addComment}
              />
            </InnerBox>
          </InnerBox>
        </Chapter>

        {errand?.execute_end && (
          <Chapter title="Utförande">
            <InnerBox>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StatusLabel state={colorStatus}>{display}</StatusLabel>
                <ListItemDate>
                  {moment(errand?.execute_end).format("YYYY-MM-DD HH:mm")}
                </ListItemDate>
              </div>

              <InnerBox style={{ marginTop: 8, padding: 6 }}>
                <div
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 500,
                    marginBottom: 6,
                  }}
                >
                  Kommentar från utförande
                </div>
                <p
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: (
                      errand?.completion_comment || "Ingen kommentar lämnades"
                    )?.replaceAll("\n", "<br>"),
                  }}
                />
              </InnerBox>
            </InnerBox>
          </Chapter>
        )}
      </ChapterWrapper>
    </>
  );
}
