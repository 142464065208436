import { ChapterWrapper } from "../../../views/styles";
import { InnerBox } from "../../sharedStyles";
import Chapter from "../Chapter";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../styles";

export default function SelectObject({
  selectedType,
  selectObject,
  apartments,
  indps,
  parkingSpots,
  commonAreas,
  brfPremises,
}) {
  return (
    <>
      <ChapterWrapper>
        {apartments?.length > 0 &&
          (selectedType?.category == null || selectedType?.category === 0) && (
            <Chapter extraStyle={{ padding: 0 }} title="Lägenheter">
              <InnerBox>
                <ListItemWrapper>
                  {apartments?.map((lc) => {
                    return (
                      <ListItem
                        onClick={() => selectObject(lc.id, "APARTMENT")}
                        key={lc.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <StatusLabel state={lc.state}>
                            {lc.state_display}
                          </StatusLabel>

                          <ListItemDate>
                            {lc.category_display}, {lc.area}m²
                          </ListItemDate>
                        </div>

                        <ListItemTitle>
                          Lgh {lc.str_representation}
                        </ListItemTitle>
                        <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                        {lc?.floors?.length > 0 && (
                          <ListItemDate>
                            Våning {lc?.floors?.join(", ") || ""}
                          </ListItemDate>
                        )}
                      </ListItem>
                    );
                  })}
                </ListItemWrapper>
              </InnerBox>
            </Chapter>
          )}
        {brfPremises?.length > 0 &&
          (selectedType?.category == null || selectedType?.category === 6) && (
            <Chapter extraStyle={{ padding: 0 }} title="BRF-Lägenheter">
              <InnerBox>
                <ListItemWrapper>
                  {brfPremises?.map((lc) => {
                    return (
                      <ListItem
                        onClick={() => selectObject(lc.id, "BRF")}
                        key={lc.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <StatusLabel state={0}>{"Bostadsrätt"}</StatusLabel>

                          <ListItemDate>
                            {lc.category_display}, {lc.area}m²
                          </ListItemDate>
                        </div>

                        <ListItemTitle>
                          Lgh {lc.str_representation}
                        </ListItemTitle>
                        <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                        {lc?.floors?.length > 0 && (
                          <ListItemDate>
                            Våning {lc?.floors?.join(", ") || ""}
                          </ListItemDate>
                        )}
                      </ListItem>
                    );
                  })}
                </ListItemWrapper>
              </InnerBox>
            </Chapter>
          )}
        {indps?.length > 0 &&
          (selectedType?.category == null || selectedType?.category === 1) && (
            <Chapter extraStyle={{ padding: 0 }} title="Lokaler">
              <InnerBox>
                <ListItemWrapper>
                  {indps?.map((lc) => {
                    return (
                      <ListItem
                        onClick={() => selectObject(lc.id, "INDP")}
                        key={lc.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <StatusLabel state={lc.state}>
                            {lc.state_display}
                          </StatusLabel>

                          <ListItemDate>
                            {lc.category_display}, {lc.area}m²
                          </ListItemDate>
                        </div>

                        <ListItemTitle>
                          Lokal {lc.str_representation}
                        </ListItemTitle>
                        <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                        {lc?.floors?.length > 0 && (
                          <ListItemDate>
                            Våning {lc?.floors?.join(", ") || ""}
                          </ListItemDate>
                        )}
                      </ListItem>
                    );
                  })}
                </ListItemWrapper>
              </InnerBox>
            </Chapter>
          )}

        {parkingSpots?.length > 0 &&
          (selectedType?.category == null || selectedType?.category === 5) && (
            <Chapter extraStyle={{ padding: 0 }} title="Parkeringar">
              <InnerBox>
                <ListItemWrapper>
                  {parkingSpots?.map((lc) => {
                    return (
                      <ListItem
                        onClick={() => selectObject(lc.id, "PARKING_SPOT")}
                        key={lc.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <StatusLabel state={lc.state}>
                            {lc.state_display}
                          </StatusLabel>

                          <ListItemDate>{lc.category_display}</ListItemDate>
                        </div>

                        <ListItemTitle>
                          Parkering {lc.str_representation}
                        </ListItemTitle>
                        <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                      </ListItem>
                    );
                  })}
                </ListItemWrapper>
              </InnerBox>
            </Chapter>
          )}

        {commonAreas?.length > 0 &&
          (selectedType?.category == null || selectedType?.category === 4) && (
            <Chapter extraStyle={{ padding: 0 }} title="Gemensamma utrymmen">
              <InnerBox>
                <ListItemWrapper>
                  {commonAreas?.map((lc) => {
                    return (
                      <ListItem
                        onClick={() => selectObject(lc.id, "COMMON_AREA")}
                        key={lc.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <ListItemDate>{lc.category_display}</ListItemDate>
                        </div>

                        <ListItemTitle>{lc.str_representation}</ListItemTitle>
                        <ListItemDate>{lc.address?.base || ""}</ListItemDate>
                        {lc?.floors?.length > 0 && (
                          <ListItemDate>
                            Våning {lc?.floors?.join(", ") || ""}
                          </ListItemDate>
                        )}
                      </ListItem>
                    );
                  })}
                </ListItemWrapper>
              </InnerBox>
            </Chapter>
          )}
      </ChapterWrapper>
    </>
  );
}
