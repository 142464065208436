import * as React from "react";
import * as SC from "./styles";

export default function Chapter({ title, children, extraStyle = {} }) {
  return (
    <SC.Chapter style={extraStyle}>
      {title && <SC.Title>{title}</SC.Title>}
      {children}
    </SC.Chapter>
  );
}
