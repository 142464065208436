export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_TENANT_PORTAL_SETTING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TENANT_PORTAL_SETTING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_TENANT_PORTAL_SETTING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TENANT_PORTAL_SETTING",
  DESTROY_FORM: "DESTROY_FORM_TENANT_PORTAL_SETTING",
  SET_FORM_ERROR: "SET_FORM_ERROR_TENANT_PORTAL_SETTING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TENANT_PORTAL_SETTING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_TENANT_PORTAL_SETTING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_TENANT_PORTAL_SETTING",
  RESET_STATE: "RESET_STATE_TENANT_PORTAL_SETTING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_TENANT_PORTAL_SETTING",
  REMOVE_OBJECT: "REMOVE_OBJECT_TENANT_PORTAL_SETTING",
  CLEAR_FETCHED: "CLEAR_FETCHED_TENANT_PORTAL_SETTING",
  SET_ANON_COMPANY_DATA: "SET_ANON_COMPANY_DATA",
  SET_ANON_LOADING: "SET_LOADING_ANON_COMPANY_KEY",

  GET_URL: "/accounts/tenants/tenant_portal_setting/",
  LIST_TERMS_URL: "/accounts/tenants/tenant_terms/list/",
  GET_ANON_URL: "/accounts/tenants/tenant_portal_setting/anon/",

  STORE_NAME: "tenantPortalSetting",
};
