import * as React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Contracts from "./views/Contracts";
import CreateErrand from "./views/CreateErrand";
import Errands from "./views/Errands";
import Inspections from "./views/Inspections";
import Home from "./views/Home";
import Login from "./views/Login";
import Objects from "./views/Objects";
import RouteBase from "./views/RouteBase";
import Notifications from "./views/Notifications";
import DocumentsAndInfo from "./views/DocumentsAndInfo";
import NewsArticle from "./views/NewsArticle";
import News from "./views/News";
import ReportErrand from "./views/ReportErrand";
import Object from "./views/Object";
import Contract from "./views/Contract";
import Profile from "./views/Profile";
import Invoicing from "./views/Invoicing";
import Invoice from "./views/Invoice";
import InspectionErrand from "./views/InspectionErrand";
import Booking from "./views/Booking";

export default function Routes() {
  return (
    <Switch>
      <RouteBase path="/profile" component={Profile} />
      <RouteBase exact path="/invoices" component={Invoicing} />
      <RouteBase path="/invoices/:id" component={Invoice} />
      <RouteBase exact path="/contracts" component={Contracts} />
      <RouteBase path="/contracts/:type/:id" component={Contract} />
      <RouteBase exact path="/objects" component={Objects} />
      <RouteBase path="/objects/:type/:id" component={Object} />
      <RouteBase exact path="/errands" component={Errands} />
      <RouteBase
        path="/errands/booking/:reportErrandId/:meetingCalendarId"
        component={Booking}
      />
      <RouteBase path="/errands/:id" component={ReportErrand} />
      <RouteBase exact path="/inspections" component={Inspections} />
      <RouteBase path="/inspections/:id" component={InspectionErrand} />
      <RouteBase path="/create-errand" component={CreateErrand} />
      <RouteBase path="/notifications" component={Notifications} />
      <RouteBase path="/documents-and-info" component={DocumentsAndInfo} />
      <RouteBase exact path="/news" component={News} />
      <RouteBase path="/news/:id" component={NewsArticle} />
      <RouteBase exact path="/" component={Home} />

      {/* Auth and password */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/:companyKey" component={Login} />

      <Redirect from="*" to="/" />
    </Switch>
  );
}
