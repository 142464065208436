import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import useCompanySetting from "../../hooks/useCompanySetting";
import useTenantInvoiceData from "../../store/tenantInvoicing/hooks/retrieve";
import theme from "../../theme";
import { SHOWN_STAGES_UNPAID } from "../../views/Invoicing";
import { InnerBox } from "../sharedStyles";
import * as SC from "./styles";
import { FiTool, FiCreditCard, FiArrowRight } from "react-icons/fi";

export default function QuickLinks() {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [tenantInvoiceData, tenantInvoiceDataLoading] = useTenantInvoiceData();

  React.useEffect(() => {
    if (tenantInvoiceData !== undefined && !tenantInvoiceDataLoading) {
      setInvoiceData(tenantInvoiceData?.InvoiceActions || null);
    }
  }, [tenantInvoiceData, tenantInvoiceDataLoading]);

  const nextUnpaidInvoice = React.useMemo(() => {
    const unpaidInvoices = invoiceData?.filter((invoice) => {
      return SHOWN_STAGES_UNPAID.includes(invoice?.Stage);
    });

    const next = unpaidInvoices?.reduce((acc, cur) => {
      if (!acc) return cur;

      if (moment(acc.DueDate).isBefore(cur.DueDate)) return cur;
    }, null);

    return next;
  }, [invoiceData]);

  const [setting] = useCompanySetting();
  const { push } = useHistory();
  return (
    <InnerBox>
      <SC.QuickLinkWrapper>
        <SC.QuickLinkItem onClick={() => push("/create-errand")}>
          <SC.QuickLinkIconWrapper style={{ borderColor: theme().colors.blue }}>
            <FiTool color={theme().colors.blue} size={24} />
          </SC.QuickLinkIconWrapper>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SC.QuickLinkTitle>Felanmälan</SC.QuickLinkTitle>
            <SC.QuickLinkSubTitle>Skapa ett nytt ärende</SC.QuickLinkSubTitle>
          </div>
          <FiArrowRight
            color={theme().colors.blue}
            size={24}
            style={{ marginLeft: "auto" }}
          />
        </SC.QuickLinkItem>
        {nextUnpaidInvoice && (
          <SC.QuickLinkItem
            onClick={() =>
              push(`/invoices/${nextUnpaidInvoice.ActionPublicId}`)
            }
          >
            <SC.QuickLinkIconWrapper
              style={{ borderColor: theme().colors.green }}
            >
              <FiCreditCard color={theme().colors.green} size={24} />
            </SC.QuickLinkIconWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SC.QuickLinkTitle>Nästa avi</SC.QuickLinkTitle>
              <SC.QuickLinkSubTitle>
                Förfaller:{" "}
                {moment(nextUnpaidInvoice?.DueDate).format("YYYY-MM-DD")}
              </SC.QuickLinkSubTitle>
            </div>
            <FiArrowRight
              color={theme().colors.green}
              size={24}
              style={{ marginLeft: "auto" }}
            />
          </SC.QuickLinkItem>
        )}
        {/* <SC.QuickLinkItem
          style={{
            backgroundColor: theme(setting?.color_code).colors.purple,
            color: theme(setting?.color_code).colors.purpleLight,
          }}
        >
          <SC.QuickLinkTitle>FAQ</SC.QuickLinkTitle>
          <SC.QuickLinkSubTitle>Vanliga frågor och svar </SC.QuickLinkSubTitle>
        </SC.QuickLinkItem> */}
      </SC.QuickLinkWrapper>
    </InnerBox>
  );
}
