import { cloneDeep } from "lodash";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import ErrandImages from "../components/Content/ErrandImages";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../components/Content/styles";
import TimeLine from "../components/Content/TimeLine";
import NonConnectedTextField from "../components/Form/NonConnectedTextField";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useApartment } from "../store/apartments";
import { buildQueryString, deleteObject } from "../store/base";
import { axiosInstance } from "../store/base/store/axios";
import { useBrfPremises } from "../store/brfPremises";
import {
  useFilteredCalendarEvents,
  constants as calendarEventConstants,
} from "../store/calendarEvents";
import { useCommonArea } from "../store/commonAreas";
import { useErrandComponent } from "../store/errandComponents";
import { getCurrentProductTypeUsage } from "../store/errandComponents/utils";
import { useIndustrialPremises } from "../store/industrialPremises";
import { reverseCreateNote, useFilteredNotes } from "../store/notes";
import { useParkingSpot } from "../store/parkingSpots";
import { useReportErrand } from "../store/reportErrands";
import { getErrandStatus } from "../store/reportErrands/utils";
import { useReportErrandSetting } from "../store/reportErrandSettings";
import { addToast, TOAST_TYPES } from "../store/toasts";
import theme from "../theme";
import { ChapterWrapper } from "./styles";

export default function ReportErrand() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { push } = useHistory();
  const [errand, errandLoading] = useReportErrand(id);
  const [newComment, setNewComment] = React.useState("");
  const [confirmRescheduleMeeting, setConfirmRescheduleMeeting] =
    React.useState("");
  const [confirmCancelMeeting, setConfirmCancelMeeting] = React.useState("");
  const [loadingCancelMeeting, setLoadingCancelMeeting] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { isMobile } = useScreenSize();
  const userId = useSelector((state) => state.app.userId);

  const [component] = useErrandComponent(errand?.component_placement?.id);
  const [errandSetting] = useReportErrandSetting(errand?.setting?.id);
  const currentProductUsage = getCurrentProductTypeUsage(component);
  const productType = currentProductUsage?.product_type;
  const componentType = productType?.component_type;

  const [apartment] = useApartment(component?.room?.apartment?.id);
  const [indp] = useIndustrialPremises(
    component?.room?.industrial_premises?.id
  );
  const [brfPremis] = useBrfPremises(component?.room?.brf_premis?.id);
  const [commonArea] = useCommonArea(component?.room?.common_area?.id);
  const [parkingSpot] = useParkingSpot(component?.parking_spot?.id);

  const premises = apartment || indp || brfPremis || commonArea || parkingSpot;

  const eventsQuery = buildQueryString({ reporterrand: id });
  const [events, eventsLoading] = useFilteredCalendarEvents(eventsQuery);
  const event = events?.[0];
  const canBookMeeting =
    errandSetting?.meeting_calendar?.id &&
    !event &&
    !eventsLoading &&
    errand?.status === 0;

  const reportCommentsQuery = buildQueryString({
    id__in: errand?.report_comments?.map((n) => n.id) || [],
  });
  const [reportComments] = useFilteredNotes(reportCommentsQuery);

  const actualComments = React.useMemo(() => {
    const actual = cloneDeep(reportComments || [])?.splice(
      1,
      reportComments?.length || 1
    );
    return actual;
  }, [reportComments]);

  const { colorStatus, display } = getErrandStatus(errand);

  const timeLineItems = React.useMemo(() => {
    if (!errand) return [];
    const items = [];

    items.push({
      title: "Ärendet skapades",
      date: moment(errand.created_at).format("YYYY-MM-DD HH:mm"),
    });

    if (errand.planned_start) {
      items.push({
        title: "Ärendet planeras att påbörjas",
        date: moment(errand.planned_start).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.execute_start) {
      items.push({
        title: "Ärendet påbörjades",
        date: moment(errand.execute_start).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.planned_end) {
      items.push({
        title: "Ärendet planeras att avslutas",
        date: moment(errand.planned_end).format("YYYY-MM-DD HH:mm"),
      });
    }

    if (errand.execute_end) {
      items.push({
        title: "Ärendet avslutades",
        date: moment(errand.execute_end).format("YYYY-MM-DD HH:mm"),
      });
    }

    return items;
  }, [errand]);

  const addComment = () => {
    setLoading(true);

    const noteObj = {
      content: newComment,
      owner: { id: userId },
      content_type: "errands.reporterrand",
      object_id: id,
      attr_name: "report_comments",
    };

    dispatch(
      reverseCreateNote({
        noteObj,
        successCallback: () => {
          setNewComment("");
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  const cancelMeeting = () => {
    setLoadingCancelMeeting(true);

    dispatch(
      deleteObject({
        noToast: true,
        instance: event,
        constants: calendarEventConstants,
        successCallback: () => {
          setLoadingCancelMeeting(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Mötet avbokades",
            })
          );
          push("/errands");
        },
        errorCallback: () => {
          setLoadingCancelMeeting(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Mötet kunde ej avbokas",
              description: "Kontakta din hyresvärd för att avboka mötet ",
            })
          );
        },
      })
    );
  };

  const rescheduleMeeting = () => {
    setLoadingCancelMeeting(true);
    dispatch(
      deleteObject({
        noToast: true,
        instance: event,
        constants: calendarEventConstants,
        successCallback: () => {
          setLoadingCancelMeeting(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Mötet avbokades",
              description: "Du kan nu boka ett nytt möte",
            })
          );
          push(
            `/errands/booking/${errand?.id}/${errandSetting?.meeting_calendar?.id}`
          );
        },
        errorCallback: () => {
          setLoadingCancelMeeting(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Mötet kunde ej avbokas",
              description: "Kontakta din hyresvärd för att avboka mötet ",
            })
          );
        },
      })
    );
  };

  const getProtocol = async () => {
    try {
      const { data } = await axiosInstance.post(
        "/reporting/generators/powerpoint/run_report/errands_reporterrandpresentationgenerator/",
        {
          additional_kwargs: {
            errand_id: parseInt(id),
          },
        }
      );

      const docId = data.id;

      const {
        data: { data: pdfData },
      } = await axiosInstance.get(
        `/reporting/generators/powerpoint/pdfprintdata/${docId}/`
      );
      setResult(pdfData);
    } catch (e) {
      return;
    }
  };

  React.useEffect(() => {
    getProtocol();
  }, []);

  const download = () => {
    const linkSource = `data:application/pdf;base64,${result}`;
    const downloadLink = document.createElement("a");
    const fileName = `${errand?.errand_id}_protokoll_${moment().format(
      "YYYY-MM-DD"
    )}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      {(errandLoading || loading || loadingCancelMeeting) && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <TextButton
        extraStyle={{ marginTop: 12 }}
        title="Tillbaka till översikt"
        iconType="arrow-back"
        clicked={() => push("/errands")}
      />

      <PageTitle
        renderAction={
          [3, 4].includes(errand?.status) && result
            ? () => (
                <PrimaryButton
                  fillWidth={isMobile}
                  extraStyle={{ marginTop: isMobile ? 12 : 0 }}
                  clicked={download}
                  title="Visa protokoll"
                />
              )
            : null
        }
      >
        {errand?.errand_id} - {errand?.str_representation}
      </PageTitle>

      {event &&
        [0, 1, 2].includes(errand?.status) &&
        moment(event?.start).isAfter(moment()) &&
        !confirmCancelMeeting &&
        !confirmRescheduleMeeting && (
          <div style={{ padding: "0 12px 12px 12px" }}>
            <ListItemWrapper>
              <ListItem>
                <ListItemTitle>
                  Kommande händelse -{" "}
                  {moment(event?.start).format("YYYY-MM-DD HH:mm")}
                </ListItemTitle>
                <ListItemDate>Videomöte för avhjälpande av ärende</ListItemDate>
                <ListItemDate style={{ marginBottom: 12 }}>
                  {event.dynamic_remind_content}
                </ListItemDate>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 400,
                  }}
                >
                  <TextButton
                    extraStyle={{ marginTop: 6, padding: 0 }}
                    title="Omboka möte"
                    iconType="sync"
                    iconPlacement="right"
                    clicked={() => setConfirmRescheduleMeeting(true)}
                  />
                  <TextButton
                    extraStyle={{ marginTop: 6, padding: 0 }}
                    title="Avboka möte"
                    iconType="close"
                    iconPlacement="right"
                    clicked={() => setConfirmCancelMeeting(true)}
                  />
                </div>
              </ListItem>
            </ListItemWrapper>
          </div>
        )}

      {confirmCancelMeeting ? (
        <ChapterWrapper>
          <ListItem>
            <ListItemTitle>Bekräfta avbokning av möte</ListItemTitle>
            <ListItemDate>Detta möte kommer att avbokas.</ListItemDate>

            <div
              style={{ marginTop: 12, display: "flex", width: "100%", flex: 1 }}
            >
              <PrimaryButton
                extraStyle={{ flex: 1 }}
                title="Boka av möte"
                clicked={cancelMeeting}
              />
            </div>
          </ListItem>
        </ChapterWrapper>
      ) : confirmRescheduleMeeting ? (
        <ChapterWrapper>
          <ListItem>
            <ListItemTitle>Bekräfta ombokning av möte</ListItemTitle>
            <ListItemDate>
              Du kommer att få välja en ny tid för videomöte. Detta möte kommer
              att avbokas.
            </ListItemDate>

            <div
              style={{ marginTop: 12, display: "flex", width: "100%", flex: 1 }}
            >
              <PrimaryButton
                extraStyle={{ flex: 1 }}
                title="Boka om möte"
                clicked={rescheduleMeeting}
              />
            </div>
          </ListItem>
        </ChapterWrapper>
      ) : (
        <ChapterWrapper>
          <Chapter title="Ärendeinformation">
            <InnerBox>
              <InfoItem title="Ärendenummer" value={errand?.errand_id} />
              <InfoItem title="Titel" value={errand?.title} />
              <InfoItem
                title="Felanmälningstyp"
                value={errand?.setting?.str_representation}
              />
              <InfoItem
                title={
                  apartment
                    ? "Lägenhet"
                    : brfPremis
                    ? "BRF-lägenhet"
                    : indp
                    ? "Lokal"
                    : commonArea
                    ? "Gemensamt utrymme"
                    : parkingSpot
                    ? "Parkeringsplats"
                    : ""
                }
                value={premises?.str_representation}
              />
              <InfoItem
                title="Komponent"
                value={componentType?.str_representation}
              />
              <InfoItem
                title="Uppdaterades senast"
                value={moment(errand?.updated_at).format("YYYY-MM-DD HH:mm")}
              />

              {canBookMeeting && (
                <PrimaryButton
                  extraStyle={{ marginTop: 8 }}
                  title="Boka ett digitalt möte för avhjälpande"
                  clicked={() =>
                    push(
                      `/errands/booking/${errand?.id}/${errandSetting?.meeting_calendar?.id}`
                    )
                  }
                />
              )}

              <InnerBox style={{ marginTop: 8, padding: 6 }}>
                <div
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 500,
                    marginBottom: 6,
                  }}
                >
                  Beskrivning
                </div>
                <p
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: reportComments?.[0]?.content?.replaceAll(
                      "\n",
                      "<br>"
                    ),
                  }}
                />
              </InnerBox>

              <InnerBox style={{ marginTop: 8, padding: 6 }}>
                <div
                  style={{
                    fontSize: theme().fontSizes.headerXSmall,
                    fontWeight: 500,
                    marginBottom: 6,
                  }}
                >
                  Bilder
                </div>

                {errand?.report_files?.length > 0 ? (
                  <ErrandImages images={errand?.report_files} />
                ) : (
                  <ListItemTitle>Ärendet saknar bilder</ListItemTitle>
                )}
              </InnerBox>
            </InnerBox>
          </Chapter>

          <Chapter title="Ärendestatus">
            <InnerBox>
              <TimeLine items={timeLineItems} />
            </InnerBox>
          </Chapter>

          <Chapter title="Kommentarer">
            <InnerBox>
              <ListItemWrapper>
                {actualComments?.map((c) => (
                  <ListItem
                    style={{
                      backgroundColor:
                        c.owner?.id == userId
                          ? theme().colors.greenLight
                          : theme().colors.yellowLight,
                    }}
                    key={c.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItemDate>
                        {c.owner?.id == userId
                          ? "Jag"
                          : c.owner?.str_representation}{" "}
                      </ListItemDate>
                      <ListItemDate>
                        {moment(c.created_at).format("YYYY-MM-DD")}
                      </ListItemDate>
                    </div>
                    <ListItemTitle>{c.content}</ListItemTitle>
                  </ListItem>
                ))}
              </ListItemWrapper>

              <InnerBox style={{ marginTop: 12 }}>
                <NonConnectedTextField
                  {...{
                    noMargin: true,

                    title: "Lägg till kommentar",

                    noResize: true,
                    value: newComment,
                    handleChange: (val) => setNewComment(val),
                    placeholder: "Skriv din kommentar här",
                    rows: 2,
                  }}
                />
                <PrimaryButton
                  disabled={!newComment?.length}
                  extraStyle={{ marginTop: 4 }}
                  title="Lägg till kommentar"
                  clicked={addComment}
                />
              </InnerBox>
            </InnerBox>
          </Chapter>

          {errand?.execute_end && (
            <Chapter title="Utförande">
              <InnerBox>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StatusLabel state={colorStatus}>{display}</StatusLabel>
                  <ListItemDate>
                    {moment(errand?.execute_end).format("YYYY-MM-DD HH:mm")}
                  </ListItemDate>
                </div>

                <InnerBox style={{ marginTop: 8, padding: 6 }}>
                  <div
                    style={{
                      fontSize: theme().fontSizes.headerXSmall,
                      fontWeight: 500,
                      marginBottom: 6,
                    }}
                  >
                    Kommentar från utförande
                  </div>
                  <p
                    style={{
                      fontSize: theme().fontSizes.headerXSmall,
                      fontWeight: 400,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: (
                        errand?.completion_comment || "Ingen kommentar lämnades"
                      )?.replaceAll("\n", "<br>"),
                    }}
                  />
                </InnerBox>
              </InnerBox>
            </Chapter>
          )}
        </ChapterWrapper>
      )}
    </>
  );
}
