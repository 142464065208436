import { useObject } from "../../base";
import { getSingle } from "../store/actions";
import constants from "../store/constants";

export function useTenantPortalSetting(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}
