import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import appReducer from "./app/reducer";
import { reducer as toastReducer, constants as toastConstants } from "./toasts";
import {
  reducer as leaseContractReducer,
  constants as leaseContractConstants,
} from "./leaseContracts";
import {
  reducer as parkingContractReducer,
  constants as parkingContractConstants,
} from "./parkingContracts";
import {
  reducer as otherContractReducer,
  constants as otherContractConstants,
} from "./otherContracts";
import {
  reducer as apartmentReducer,
  constants as apartmentConstants,
} from "./apartments";
import {
  reducer as brfPremisesReducer,
  constants as brfPremisesConstants,
} from "./brfPremises";
import {
  reducer as commonAreaReducer,
  constants as commonAreaConstants,
} from "./commonAreas";
import {
  reducer as industrialPremisesReducer,
  constants as industrialPremisesConstants,
} from "./industrialPremises";
import {
  reducer as parkingSpotReducer,
  constants as parkingSpotConstants,
} from "./parkingSpots";
import {
  reducer as portalSettingReducer,
  constants as portalSettingConstants,
} from "./portalSettings";
import {
  reducer as componentsReducer,
  constants as componentsConstants,
} from "./errandComponents";
import {
  reducer as sendoutsReducer,
  constants as sendoutsConstants,
} from "./sendout";
import {
  reducer as notificationsReducer,
  constants as notificationsConstants,
} from "./notifications";
import {
  reducer as checklistRowsReducer,
  constants as checklistRowsConstants,
} from "./checklistRows";
import {
  reducer as reportErrandSettingsReducer,
  constants as reportErrandSettingsConstants,
} from "./reportErrandSettings";
import {
  reducer as reportErrandReducer,
  constants as reportErrandConstants,
} from "./reportErrands";
import {
  reducer as inspectionErrandReducer,
  constants as inspectionErrandConstants,
} from "./inspectionErrands";
import { reducer as notesReducer, constants as notesConstants } from "./notes";
import {
  reducer as editableDocReducer,
  constants as editableDocConstants,
} from "./editabledocs";
import {
  reducer as tenantReducer,
  constants as tenantConstants,
} from "./tenants";
import { reducer as userReducer, constants as userConstants } from "./users";
import {
  reducer as tenantInvoicingReducer,
  constants as tenantInvoicingConstants,
} from "./tenantInvoicing";
import {
  reducer as calendarReducer,
  constants as calendarConstants,
} from "./calendar";
import {
  reducer as calendarEventReducer,
  constants as calendarEventConstants,
} from "./calendarEvents";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    [userConstants.STORE_NAME]: userReducer,
    [portalSettingConstants.STORE_NAME]: portalSettingReducer,
    [sendoutsConstants.STORE_NAME]: sendoutsReducer,
    [notificationsConstants.STORE_NAME]: notificationsReducer,
    [toastConstants.STORE_NAME]: toastReducer,
    [leaseContractConstants.STORE_NAME]: leaseContractReducer,
    [parkingContractConstants.STORE_NAME]: parkingContractReducer,
    [otherContractConstants.STORE_NAME]: otherContractReducer,
    [apartmentConstants.STORE_NAME]: apartmentReducer,
    [brfPremisesConstants.STORE_NAME]: brfPremisesReducer,
    [commonAreaConstants.STORE_NAME]: commonAreaReducer,
    [industrialPremisesConstants.STORE_NAME]: industrialPremisesReducer,
    [parkingSpotConstants.STORE_NAME]: parkingSpotReducer,
    [componentsConstants.STORE_NAME]: componentsReducer,
    [checklistRowsConstants.STORE_NAME]: checklistRowsReducer,
    [reportErrandSettingsConstants.STORE_NAME]: reportErrandSettingsReducer,
    [reportErrandConstants.STORE_NAME]: reportErrandReducer,
    [inspectionErrandConstants.STORE_NAME]: inspectionErrandReducer,
    [notesConstants.STORE_NAME]: notesReducer,
    [editableDocConstants.STORE_NAME]: editableDocReducer,
    [tenantConstants.STORE_NAME]: tenantReducer,
    [tenantInvoicingConstants.STORE_NAME]: tenantInvoicingReducer,
    [calendarConstants.STORE_NAME]: calendarReducer,
    [calendarEventConstants.STORE_NAME]: calendarEventReducer,
  });

export default createRootReducer;
