import { useHistory } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import PageTitle from "../components/Content/PageTitle";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
  StatusLabel,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useFilteredLeaseContracts } from "../store/leaseContracts";
import { useFilteredOtherContracts } from "../store/otherContracts";
import { useFilteredParkingContracts } from "../store/parkingContracts";
import { ChapterWrapper } from "./styles";

export default function Contracts() {
  const [leaseContracts, leaseContractsLoading] = useFilteredLeaseContracts("");
  const [otherContracts, otherContractsLoading] = useFilteredOtherContracts("");
  const [parkingContracts, parkingContractsLoading] =
    useFilteredParkingContracts("");
  const { isMobile } = useScreenSize();

  const { push } = useHistory();

  return (
    <>
      {(leaseContractsLoading ||
        otherContractsLoading ||
        parkingContractsLoading) && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <PageTitle subtitle={"Gå in på ett avtal för att se detaljer"}>
        Mina avtal
      </PageTitle>

      <ChapterWrapper>
        <Chapter title="Mina hyresavtal">
          <InnerBox>
            <ListItemWrapper>
              {leaseContracts?.map((lc) => {
                return (
                  <ListItem
                    onClick={() => push(`/contracts/lease-contract/${lc.id}`)}
                    key={lc.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 12,
                      }}
                    >
                      <StatusLabel state={lc.state}>
                        {lc.state_display}
                      </StatusLabel>

                      <ListItemDate>{lc.start_date}</ListItemDate>
                    </div>

                    <ListItemTitle>
                      Avtalsnummer: {lc.str_representation}
                    </ListItemTitle>
                  </ListItem>
                );
              })}

              {leaseContracts?.length === 0 && (
                <ListItem>
                  <ListItemDate>
                    Det finns inga hyresavtal att visa
                  </ListItemDate>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>

        <Chapter title="Mina parkeringsavtal">
          <InnerBox>
            <ListItemWrapper>
              {parkingContracts?.map((lc) => {
                return (
                  <ListItem
                    onClick={() => push(`/contracts/parking-contract/${lc.id}`)}
                    key={lc.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 12,
                      }}
                    >
                      <StatusLabel state={lc.state}>
                        {lc.state_display}
                      </StatusLabel>

                      <ListItemDate>{lc.start_date}</ListItemDate>
                    </div>

                    <ListItemTitle>
                      Avtalsnummer: {lc.str_representation}
                    </ListItemTitle>
                  </ListItem>
                );
              })}

              {parkingContracts?.length === 0 && (
                <ListItem>
                  <ListItemDate>
                    Det finns inga parkeringsavtal att visa
                  </ListItemDate>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>

        <Chapter title="Mina övriga avtal">
          <InnerBox>
            <ListItemWrapper>
              {otherContracts?.map((lc) => {
                return (
                  <ListItem
                    onClick={() => push(`/contracts/other-contract/${lc.id}`)}
                    key={lc.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 12,
                      }}
                    >
                      <StatusLabel state={lc.state}>
                        {lc.state_display}
                      </StatusLabel>

                      <ListItemDate>{lc.start_date}</ListItemDate>
                    </div>

                    <ListItemTitle>
                      Avtalsnummer: {lc.str_representation}
                    </ListItemTitle>
                  </ListItem>
                );
              })}

              {otherContracts?.length === 0 && (
                <ListItem>
                  <ListItemDate>
                    Det finns inga parkeringsavtal att visa
                  </ListItemDate>
                </ListItem>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
