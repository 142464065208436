import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import Chapter from "../components/Content/Chapter";

import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import PageTitle from "../components/Content/PageTitle";
import NotificationForm from "../components/Form/NotificationForm";
import { InnerBox } from "../components/sharedStyles";
import { useTenant } from "../store/tenants";
import { useInvoiceConfig } from "../store/tenantInvoicing";
import { ChapterWrapper } from "./styles";
import UserForm from "../components/Form/UserForm";
import useCompanySetting from "../hooks/useCompanySetting";
import { ListItemTitle, StatusLabel } from "../components/Content/styles";
import TextButton from "../components/Buttons/TextButton";
import { resetPassword } from "../store/users";
import useScreenSize from "../hooks/useScreenSize";

export default function Profile() {
  const dispatch = useDispatch();
  const [editActive, setEditActive] = React.useState(false);
  const [resetPasswordActive, setResetPasswordActive] = React.useState(false);
  const user = useSelector((state) => state.app.user);
  const [tenant] = useTenant(user?.tenant?.id);
  const debtorConfigId = tenant?.debtor_invoice_config?.id;
  const [invoiceConfig] = useInvoiceConfig(debtorConfigId);
  const { isMobile } = useScreenSize();

  const [companySetting] = useCompanySetting();

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle>Min profil</PageTitle>

      {editActive ? (
        <ChapterWrapper>
          <InnerBox style={{ marginBottom: 200 }}>
            <UserForm onCheckout={() => setEditActive(false)} />
          </InnerBox>
        </ChapterWrapper>
      ) : resetPasswordActive ? (
        <ChapterWrapper>
          <InnerBox style={{ marginBottom: 200 }}>
            <TextButton
              title="Avbryt och gå tillbaka"
              iconType="arrow-back"
              clicked={() => setResetPasswordActive(false)}
              extraStyle={{ marginBottom: 12 }}
            />

            <ListItemTitle>
              En återställningslänk kommer skickas till din kopplade
              E-postadress. Du kommer att loggas ut direkt.
            </ListItemTitle>

            <PrimaryButton
              extraStyle={{ marginTop: 12 }}
              title="Skicka återställningslänk"
              clicked={() => {
                dispatch(
                  resetPassword({
                    username: user?.username,
                    email: user?.email,
                  })
                );
              }}
            />
          </InnerBox>
        </ChapterWrapper>
      ) : (
        <ChapterWrapper>
          <Chapter title="Information">
            <InnerBox>
              <InfoItem title="Användarnamn" value={user?.username} />
              <InfoItem
                title="Namn"
                value={`${user?.first_name || ""} ${user?.last_name || ""}`}
              />
              {user?.corporate_name && (
                <InfoItem title="Bolag" value={user?.corporate_name} />
              )}
              <InfoItem title="E-post" value={user?.email} />
              <InfoItem title="Telefon" value={user?.phone} />
              <InfoItem title="Personnummer" value={user?.legal_id} />

              {companySetting &&
                !companySetting?.hide_personal_info_edit_fields && (
                  <PrimaryButton
                    extraStyle={{ marginTop: 12 }}
                    title="Redigera information"
                    clicked={() => setEditActive(true)}
                  />
                )}

              <PrimaryButton
                extraStyle={{ marginTop: 12 }}
                title="Byt lösenord"
                clicked={() => setResetPasswordActive(true)}
              />
            </InnerBox>
          </Chapter>

          <Chapter title="Notisinställningar">
            <InnerBox>
              <NotificationForm />
            </InnerBox>
          </Chapter>

          {invoiceConfig && (
            <Chapter title="Fakturainformation">
              <InnerBox>
                <InfoItem
                  title="Leveransmetod"
                  value={invoiceConfig?.delivery_method_display}
                />
                <InfoItem
                  title="Fakturaadress"
                  value={invoiceConfig?.invoice_address?.base?.split(",")?.[0]}
                />
                <InfoItem
                  title="Fakturaadress postnr."
                  value={invoiceConfig?.invoice_address?.postal_code}
                />
                <InfoItem
                  title="Fakturaadress stad"
                  value={invoiceConfig?.invoice_address?.city}
                />
                <InfoItem
                  title="Fakturaadress kommun"
                  value={invoiceConfig?.invoice_address?.municipality}
                />
                <InfoItem title="E-post" value={invoiceConfig?.email_invoice} />
                {invoiceConfig?.e_invoice_bank_display && (
                  <InfoItem
                    title="Bank för e-faktura"
                    value={invoiceConfig?.e_invoice_bank_display || "-"}
                  />
                )}
                <InfoItem
                  title="Autogiro"
                  value={
                    <StatusLabel state={invoiceConfig?.autogiro_number ? 0 : 3}>
                      {invoiceConfig?.autogiro_number ? "Aktivt" : "Inaktivt"}
                    </StatusLabel>
                  }
                />
              </InnerBox>
            </Chapter>
          )}
        </ChapterWrapper>
      )}
    </>
  );
}
