import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import TextButton from "../components/Buttons/TextButton";
import ChecklistCheck from "../components/Content/CreateErrand/ChecklistCheck";
import ChecklistRows from "../components/Content/CreateErrand/ChecklistRows";
import SelectComponent from "../components/Content/CreateErrand/SelectComponent";
import SelectObject from "../components/Content/CreateErrand/SelectObject";
import SelectRoom from "../components/Content/CreateErrand/SelectRoom";
import SelectSetting from "../components/Content/CreateErrand/SelectSetting";
import Hero from "../components/Content/Hero";

import { useFilteredApartments } from "../store/apartments";
import { buildQueryString, updateActiveFormInstance } from "../store/base";
import { useFilteredChecklistRows } from "../store/checklistRows";
import { getReportErrandChecklistIdForComponent } from "../store/errandComponents/utils";
import { useFilteredIndustrialPremises } from "../store/industrialPremises";
import { useFilteredParkingSpots } from "../store/parkingSpots";
import { create, useReportErrandForm, constants } from "../store/reportErrands";
import { create as createBooking } from "../store/calendarEvents";
import { useFilteredReportErrandSettings } from "../store/reportErrandSettings";
import { cloneDeep } from "lodash";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import { useHistory } from "react-router-dom";
import Description from "../components/Content/CreateErrand/Description";
import PageTitle from "../components/Content/PageTitle";
import Booking from "../components/Content/CreateErrand/Booking";
import { addToast, TOAST_TYPES } from "../store/toasts";
import { useFilteredCommonAreas } from "../store/commonAreas";
import { useFilteredBrfPremises } from "../store/brfPremises";
import useScreenSize from "../hooks/useScreenSize";

const STEPS = {
  SELECT_TYPE: 0,
  SELECT_OBJECT: 1,
  SELECT_ROOM: 2,
  SELECT_COMPONENT: 3,
  CHECKLIST_ROWS: 4,
  DESCRIPTION: 5,
  SUMMARY: 6,
  CHECKLIST_CHECK: 7,
  BOOKING: 8,
};

const STEP_INSTRUCTIONS = {
  0: "Välj vilken typ av felanmälan du vill göra",
  1: "Välj vilket objekt det gäller",
  2: "Välj vilket rum det gäller",
  3: "Välj komponent",
  4: 'Gå igenom checklistan för självavhjälpande. Tryck "Ja" om kontrollen genomfördes utan problem och "Nej" om kontrollen misslyckades.',
  5: "Detaljer kring felet",
  6: "Kontrollera datan och spara felanmälan",
  7: "Ange om felet avhjälptes av egenkontrollen. Om felet kvarstår så skapas i nästa steg en felanmälan. ",
  8: "Boka en tid för ett digitalt möte",
};

export default function CreateErrand() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [currentStep, setCurrentStep] = React.useState(STEPS.SELECT_TYPE);
  const [selectedObjectType, setSelectedObjectType] = React.useState(null);
  const [selectedObjectId, setSelectedObjectId] = React.useState(null);
  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const [selectedType, setSelectedType] = React.useState(null);
  const [checklistId, setChecklistId] = React.useState(null);
  const [checklistIndex, setChecklistIndex] = React.useState(0);
  const [acceptedRows, setAcceptedRows] = React.useState([]);
  const [declinedRows, setDeclinedRows] = React.useState([]);
  const [selectedBookingTime, setSelectedBookingTime] = React.useState(null);
  const [selectedCalendar, setSelectedCalendar] = React.useState(null);
  const { push } = useHistory();

  const { isMobile } = useScreenSize();
  const [loading, setLoading] = React.useState(false);

  useReportErrandForm("POST");

  const checklistRowQ = buildQueryString({
    check_list: checklistId,
  });
  const [activeChecklistRows] = useFilteredChecklistRows(checklistRowQ);

  const [apartments, apartmentsLoading] = useFilteredApartments("");
  const [indps, indpsLoading] = useFilteredIndustrialPremises("");
  const [parkingSpots, parkingSpotsLoading] = useFilteredParkingSpots("");
  const [commonAreas, commonAreasLoading] = useFilteredCommonAreas("");
  const [brfPremises, brfPremisesLoading] = useFilteredBrfPremises("");

  const totalPremisesLength =
    (apartments?.length || 0) +
    (indps?.length || 0) +
    (parkingSpots?.length || 0) +
    (commonAreas?.length || 0) +
    (brfPremises?.length || 0);

  const hasApartments = apartments?.length > 0;
  const hasIndp = indps?.length > 0;
  const hasParking = parkingSpots?.length > 0;
  const hasCommon = commonAreas?.length > 0;
  const hasBrfPremis = brfPremises?.length > 0;

  console.log({ hasApartments, hasIndp, hasParking, hasCommon, hasBrfPremis });

  const excludeCategories = [];
  if (!hasApartments) excludeCategories.push(0);
  if (!hasIndp) excludeCategories.push(1);
  if (!hasCommon) excludeCategories.push(4);
  if (!hasParking) excludeCategories.push(5);
  if (!hasBrfPremis) excludeCategories.push(6);

  console.log({ excludeCategories });

  const settingsQ = buildQueryString({
    "category__in!": excludeCategories?.length ? excludeCategories : undefined,
  });

  const [reportErrandSettings, reportErrandSettingsLoading] =
    useFilteredReportErrandSettings(settingsQ);

  const handleGoBack = () => {
    switch (currentStep) {
      case STEPS.SELECT_OBJECT:
        setCurrentStep(STEPS.SELECT_TYPE);
        setSelectedType(null);
        break;
      case STEPS.SELECT_ROOM:
        setCurrentStep(STEPS.SELECT_OBJECT);
        setSelectedObjectId(null);
        setSelectedObjectType(null);
        break;
      case STEPS.SELECT_COMPONENT: {
        setCurrentStep(STEPS.SELECT_OBJECT);
        setSelectedRoom(null);
        setSelectedObjectId(null);
        setSelectedObjectType(null);
        break;
      }
      case STEPS.CHECKLIST_ROWS: {
        setCurrentStep(STEPS.SELECT_COMPONENT);
        setSelectedComponent(null);
        setChecklistId(null);
        setChecklistIndex(0);
        setAcceptedRows([]);
        setDeclinedRows([]);
        break;
      }

      case STEPS.DESCRIPTION: {
        setCurrentStep(STEPS.SELECT_COMPONENT);
        setSelectedComponent(null);
        setChecklistId(null);
        setChecklistIndex(0);
        setAcceptedRows([]);
        setDeclinedRows([]);
        break;
      }

      case STEPS.BOOKING: {
        setCurrentStep(STEPS.SELECT_COMPONENT);
        break;
      }
      default:
        return null;
    }
  };

  const showGoBack = () => {
    switch (currentStep) {
      case STEPS.SELECT_OBJECT:
        return reportErrandSettings?.length > 1;
      case STEPS.SELECT_ROOM:
        return totalPremisesLength > 1;
      case STEPS.SELECT_COMPONENT:
        return true;
      case STEPS.CHECKLIST_ROWS:
        return true;
      case STEPS.BOOKING:
        return true;
      case STEPS.DESCRIPTION:
        return true;
      default:
        return false;
    }
  };

  const selectSetting = (setting) => {
    setSelectedType(setting);
    setCurrentStep(STEPS.SELECT_OBJECT);
  };

  const selectObject = (id, type) => {
    setSelectedObjectId(id);
    setSelectedObjectType(type);
    setCurrentStep(STEPS.SELECT_ROOM);
  };

  const selectRoom = (room) => {
    setSelectedRoom(room);
    setCurrentStep(STEPS.SELECT_COMPONENT);
  };

  const selectComponent = (component) => {
    setSelectedComponent(component);
    const checklist = getReportErrandChecklistIdForComponent(component);

    // if checklist, go through checklist rows
    if (checklist) {
      setChecklistId(checklist);
      setCurrentStep(STEPS.CHECKLIST_ROWS);
    } else {
      setChecklistId(null);
      onGoToDescription(component);
    }
  };

  const onAcceptRow = (rowId) => {
    const acceptedRowsClone = cloneDeep(acceptedRows);

    acceptedRowsClone.push(rowId);

    setAcceptedRows(acceptedRowsClone);

    // if more rows, go on, else ask if fault remains
    if (activeChecklistRows[checklistIndex + 1]) {
      setChecklistIndex(checklistIndex + 1);
    } else {
      setCurrentStep(STEPS.CHECKLIST_CHECK);
    }
  };

  const onDeclineRow = (rowId) => {
    const declinedRowsClone = cloneDeep(declinedRows);

    declinedRowsClone.push(rowId);

    setDeclinedRows(declinedRowsClone);

    // if more rows, go on, else ask if fault remains
    if (activeChecklistRows[checklistIndex + 1]) {
      setChecklistIndex(checklistIndex + 1);
    } else {
      setCurrentStep(STEPS.CHECKLIST_CHECK);
    }
  };

  // create resolved by tenant report errand
  const onFaultResolved = () => {
    const newReportErrand = {
      component_placement: selectedComponent,
      setting: selectedType,
      execution_rating: {
        is_ok: true,
        note: "Egenkontroll av hyresgäst",
        component_placement: selectedComponent,
        ok_check_list_rows: (acceptedRows || []).map((r) => ({ id: r })),
        tenant_not_ok_check_list_rows: (declinedRows || []).map((r) => ({
          id: r,
        })),
      },
      title: `Självavhjälpt ${moment().format("YYYY-MM-DD HH:mm")}`,
      status: 4,
      report_comments: [
        {
          content: `Anmälan avhjälptes med hjälp av egenkontroll via "Mina sidor"`,
        },
      ],
    };

    setLoading(true);

    dispatch(
      create({
        forceData: newReportErrand,
        successCallback: () => {
          setLoading(false);

          push("/errands");
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  // go to final report errand step
  const onFaultRemains = () => {
    onGoToDescription();
  };

  const onGoToDescription = (component) => {
    // state does not have time to update in time, so for some scenarios the component needs to be passed here
    const newReportErrand = {
      component_placement: component || selectedComponent,
      setting: selectedType,
      status: 0,
    };

    // add checklist answers if checklist
    if (checklistId) {
      newReportErrand["execution_rating"] = {
        is_ok: false,
        note: "",
        component_placement: selectedComponent,
        ok_check_list_rows: (acceptedRows || []).map((r) => ({ id: r })),
        tenant_not_ok_check_list_rows: (declinedRows || []).map((r) => ({
          id: r,
        })),
      };
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: newReportErrand,
      })
    );

    // go to time selection if a meeting calendar is connected
    if (
      selectedType?.meeting_calendar &&
      (selectedObjectType === "APARTMENT" ||
        selectedObjectType === "INDP" ||
        selectedObjectType === "BRF")
    ) {
      setCurrentStep(STEPS.BOOKING);
    } else {
      setCurrentStep(STEPS.DESCRIPTION);
    }
  };

  const onBookingTimeSelected = ({ selectedTime, calendar }) => {
    if (selectedTime) {
      setSelectedBookingTime(selectedTime);
      setSelectedCalendar(calendar);
      setCurrentStep(STEPS.DESCRIPTION);
    } else {
      setCurrentStep(STEPS.DESCRIPTION);
    }
  };

  const onCreate = () => {
    setLoading(true);
    dispatch(
      create({
        preventDefaultToast: true,
        successCallback: (_, returnedData) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Felanmälan skapades",
              description: `Du hittar den och kan följa status under "Mina aktiva ärenden"`,
            })
          );
          if (
            selectedType?.meeting_calendar &&
            selectedBookingTime &&
            selectedCalendar
          ) {
            const meetingObj = {
              start: selectedBookingTime?.From,
              end: selectedBookingTime?.To,
              content_type: "errands.reporterrand",
              object_id: returnedData?.id,
              calendar: selectedCalendar,
            };

            dispatch(
              createBooking({
                preventDefaultToast: true,
                forceData: meetingObj,
                successCallback: () => {
                  setLoading(false);
                  push("/errands");

                  dispatch(
                    addToast({
                      type: TOAST_TYPES.SUCCESS,
                      title: "Ett möte för avhälpande bokades in",
                      description: `Detaljer kring ditt möte hittar du under "Kommande möten" på startsidan.`,
                    })
                  );
                },
                errorCallback: () => {
                  setLoading(false);
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.ERROR,
                      title: "Mötesbokningen kunde inte genomföras",
                      description:
                        "Din felanmälan är skapad men bokningen kunde inte genomföras. Kontakta din hyresvärd för mer information.",
                    })
                  );
                  push("/errands");
                },
              })
            );
          } else {
            setLoading(false);
            push("/errands");
          }
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel i skapandet",
              description: `Kontrollera datan och försök igen`,
            })
          );
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {(loading || reportErrandSettingsLoading) && <OverlaySpinner />}
      {!isMobile && <Hero />}

      {showGoBack() && (
        <TextButton
          extraStyle={{ marginTop: 6 }}
          title="Tillbaka"
          clicked={handleGoBack}
          iconType="arrow-back"
        />
      )}

      <PageTitle subtitle={STEP_INSTRUCTIONS[currentStep]}>
        Skapa felanmälan
      </PageTitle>

      {currentStep === STEPS.SELECT_TYPE && (
        <SelectSetting {...{ selectSetting, settings: reportErrandSettings }} />
      )}
      {currentStep === STEPS.SELECT_OBJECT && (
        <SelectObject
          {...{
            selectedType,
            selectObject,
            apartments,
            indps,
            parkingSpots,
            commonAreas,
            brfPremises,
          }}
        />
      )}

      {currentStep === STEPS.SELECT_ROOM && (
        <SelectRoom {...{ selectRoom, selectedObjectId, selectedObjectType }} />
      )}

      {currentStep === STEPS.SELECT_COMPONENT && (
        <SelectComponent {...{ selectComponent, selectedRoom }} />
      )}

      {currentStep === STEPS.CHECKLIST_ROWS && (
        <ChecklistRows
          {...{
            checklistIndex,
            checklistRows: activeChecklistRows,
            onAcceptRow,
            onDeclineRow,
          }}
        />
      )}

      {currentStep === STEPS.CHECKLIST_CHECK && (
        <ChecklistCheck {...{ onFaultResolved, onFaultRemains }} />
      )}

      {currentStep === STEPS.BOOKING && (
        <Booking
          {...{
            onBookingTimeSelected,
            meetingCalendarId: selectedType?.meeting_calendar?.id,
          }}
        />
      )}

      {currentStep === STEPS.DESCRIPTION && <Description {...{ onCreate }} />}
    </>
  );
}
