import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import { useSettingForm, constants, update } from "../../store/notifications";
import { useNotificationSetting } from "../../store/notifications/hooks/retrieve";
import PrimaryButton from "../Buttons/PrimaryButton";
import OverlaySpinner from "../Content/Loaders/OverlaySpinner";
import Checkbox from "./Checkbox";
import Select from "./Select";

export default function NotificationForm() {
  const dispatch = useDispatch();
  useSettingForm();
  const storeName = constants.STORE_NAME;
  const method = "PATCH";
  const [loading, setLoading] = React.useState(false);
  const [hasSetInitial, setHasSetInitial] = React.useState(false);

  const [notifConfig] = useNotificationSetting();

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const hasChange =
    instance?.send_email != notifConfig?.send_email ||
    instance?.blocked_object_types != notifConfig?.blocked_object_types;

  React.useEffect(() => {
    if (notifConfig && !hasSetInitial) {
      setHasSetInitial(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: notifConfig,
        })
      );
    }
  }, [notifConfig, dispatch, storeName, hasSetInitial]);

  const saveUpdate = () => {
    setLoading(true);

    dispatch(
      update({
        forceData: {
          send_email: instance?.send_email,
          blocked_object_types: instance?.blocked_object_types,
        },
        successCallback: (_, returnedData) => {
          setLoading(false);

          dispatch(
            updateActiveFormInstance({
              storeName,
              data: returnedData,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Checkbox
        storeName={storeName}
        fieldKey={"send_email"}
        method={method}
        title="Få e-post vid notis"
        noMargin
      />

      <Select
        extraStyles={{ marginTop: 12 }}
        noMargin
        storeName={storeName}
        fieldKey={"blocked_object_types"}
        method={method}
        isMulti={true}
        bodyPortaled
        title="Stäng av notiser från..."
        forceChoices={OBJECT_TYPES}
      />

      {hasChange && (
        <PrimaryButton
          extraStyle={{ marginTop: 12 }}
          title="Uppdatera inställning"
          clicked={saveUpdate}
        />
      )}
    </>
  );
}

const OBJECT_TYPES = [
  { v: "standard.leasecontract", d: "Hyresavtal" },
  { v: "standard.othercontract", d: "Övriga avtal" },
  { v: "standard.parkingcontract", d: "Parkeringsavtal" },
  { v: "standard.apartment", d: "Lägenheter" },
  { v: "standard.industrialpremises", d: "Lokaler" },
  { v: "brf.brfpremis", d: "Brf lägenheter" },
  { v: "errands.reporterrand", d: "Felanmälningar" },
];
