import { cloneDeep, set } from "lodash";

export const getFormFetchProcessName = (method) => {
  return `form${method}`;
};

export const getSingleFetchProcessName = (id) => {
  return `GET_${id}`;
};

export const getAllFetchProcessName = () => {
  return "all";
};

export const getFormInstanceStateType = (storeName) => {
  return `formInstance${storeName}`;
};

export const getFormErrorStateType = (storeName) => {
  return `formError${storeName}`;
};

export const buildQueryString = (params) => {
  var esc = encodeURIComponent;
  let res = [];
  Object.keys(params).forEach((k) => {
    const e = params?.[k];
    if (e === undefined || e === null) {
      return;
    }

    if (Array.isArray(e)) {
      if (!e.length) {
        // let this indicate an empty array
        res.push(esc(k) + "=");
      } else {
        res.push(`${esc(k)}=${e.map((v) => esc(v)).join(",")}`);
      }
    } else {
      res.push(esc(k) + "=" + esc(e));
    }
  });

  if (!res.length) {
    return "";
  }

  return res.join("&");
};

export const appendQueryString = (base, addon) => {
  if (!base) {
    return addon;
  }
  if (!addon) {
    return base;
  }
  return `${base}&${addon}`;
};

export const getManyNestedCreations = (posted, retrieved) => {
  // get all objects on postedData, that had id:s
  // they will not be created

  let nonCreatedIds = [];
  posted.forEach((p) => {
    if (p.id) {
      nonCreatedIds.push(p.id);
    }
  });

  return retrieved.filter((r) => !nonCreatedIds.includes(r.id));
};

export const formatPermission = (kind, action) => {
  return `${action}_${kind}`;
};

export const stripInstanceFields = (instance, fields) => {
  let copiedInstance = cloneDeep(instance);
  fields.forEach((f) => {
    set(copiedInstance, f, undefined);
  });

  return copiedInstance;
};

export const fullyQualifiedUrl = (urlAddon) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return `http://localhost:8000${urlAddon}`;
  } else if (window.location.host.includes("demo.portal")) {
    return `https://v2alpha.api.pigello.se${urlAddon}`;
  } else if (window.location.host.includes("staging.portal")) {
    return `https://v2staging.api.pigello.se${urlAddon}`;
  } else {
    return `https://api.pigello.se${urlAddon}`;
  }
};
