import { axiosInstance } from "../base/store/axios";
import { store } from "../store";
import { push } from "connected-react-router";

export async function getUser(userId) {
  try {
    const { data: user } = await axiosInstance.get(
      `/accounts/users/user/${userId}/`
    );
    return user;
  } catch (e) {
    console.error("Could not fetch user");
  }
}

export function redirect(path) {
  store.dispatch(push(path));
}
