import * as React from "react";
import { buildQueryString } from "../../../store/base";
import { useFilteredErrandComponents } from "../../../store/errandComponents";
import { getComponentsByRooms } from "../../../store/errandComponents/utils";
import { ChapterWrapper } from "../../../views/styles";
import TextButton from "../../Buttons/TextButton";
import { InnerBox } from "../../sharedStyles";
import Chapter from "../Chapter";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../styles";

const OBJECT_TYPES = {
  APARTMENT: "apartments",
  INDP: "industrial_premises_list",
  PARKING_SPOT: "parking_spot",
  COMMON_AREA: "common_areas",
  BRF: "brf_premises",
};

export default function SelectRoom({
  selectRoom,
  selectedObjectId,
  selectedObjectType,
}) {
  const isParkingSpot = selectedObjectType === "PARKING_SPOT";
  const componentQ = buildQueryString({
    [OBJECT_TYPES[selectedObjectType]]: [selectedObjectId],
    archived: false,
  });

  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components });
  }, [components]);

  React.useEffect(() => {
    if (Object.keys(componentsByRoom || {})?.length === 1) {
      selectRoom(Object.values(componentsByRoom)[0]);
    }
  }, [componentsByRoom, selectRoom]);

  if (isParkingSpot) {
    return (
      <ChapterWrapper>
        <Chapter title="Komponenter">
          <InnerBox style={{ maxWidth: 450 }}>
            <ListItemWrapper>
              {components?.length === 0 && !componentsLoading && (
                <>
                  <ListItem>
                    <ListItemTitle>
                      Detta objekt har inga komponenter som kan felanmälas.
                    </ListItemTitle>
                  </ListItem>
                </>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    );
  }

  return (
    <>
      {componentsLoading && <OverlaySpinner />}
      <ChapterWrapper>
        <Chapter title="Rum">
          <InnerBox style={{ maxWidth: 450 }}>
            <ListItemWrapper>
              {Object.values(componentsByRoom || {}).map((room) => (
                <ListItem onClick={() => selectRoom(room)} key={room.room_id}>
                  <ListItemTitle>{room.str_representation}</ListItemTitle>
                  <ListItemDate>
                    {room?.components?.length} komponent
                    {room?.components?.length > 1 ? "er" : ""}
                  </ListItemDate>

                  <TextButton
                    title="Välj rum"
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => selectRoom(room)}
                  />
                </ListItem>
              ))}

              {Object.keys(componentsByRoom || {})?.length === 0 && (
                <>
                  <ListItem>
                    <ListItemTitle>
                      Detta objekt har inga komponenter som kan felanmälas.
                    </ListItemTitle>
                  </ListItem>
                </>
              )}
            </ListItemWrapper>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
